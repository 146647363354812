export const pt = {
  next: "Próximo",
  youWontBeCharged: "Não lhe será cobrado até confirmar os detalhes da sessão",
  home: "Início",
  chats: "Chats",
  ratings: "Classificações",
  settings: "Definições",
  moreSettings: "Mais definições",
  settingsNotify: "Configurações | Waylight",
  logOut: "Sair",
  changePassword: "Alterar palavra-passe",
  receiveResetPassword:
    "Clique em 'Reset' abaixo para receber uma ligação para redefinir a palavra-passe.",
  enterEmailForResetPassword:
    "Introduza o seu email para obter uma ligação de redefinição de senha.",
  successResetPassword: "Email de redefinição de senha enviado!",
  failResetPassword: "Falha na redefinição da palavra-passe",
  reset: "Reinicialização",
  signOut: "Sair",
  availableBooking: "Disponível para reserva",
  unavailableBooking: "Indisponível para reserva",
  shareProfile: "Perfil de partilha",
  requests: "Pedidos",
  hours: "Horas",

  totalEarned: "Total ganho",
  totalHours: "Total de horas",

  runningNow: "está a funcionar agora",

  offer: "Oferta",
  gallery: "Galeria",
  photo: "Foto",

  occupation: "Ocupação",
  skills: "Habilidades",
  experts: "Peritos",
  noInformation: "Nenhuma informação",
  speaks: "Fala",
  socialLinks: "Ligações sociais",

  contacts: "Contactos",
  noOffersYet: "Não há ofertas disponíveis",
  all: "Todos",
  requested: "Solicitada",
  confirmed: "Confirmado",
  passed: "Aprovado",
  joined: "Juntou-se a",
  ongoing: "Em curso",
  canceled: "Cancelado",
  rejected: "Rejeitado",
  approved: "Aprovado",
  calendar: "Calendário",
  favorites: "Seguiu-se",
  removeAll: "Retirar tudo",
  viewProfile: "Ver perfil",
  today: "Hoje",
  upcoming: "Próxima",
  attachments: "Anexos",

  prepare: "Preparar",

  prerequisites: "Pré-requisitos",
  download: "Descarregar",
  past: "Passado",
  decline: "Declínio",
  accept: "Aceitar",

  wantDeclinePaidSession:
    "Tem a certeza de que quer rejeitar o pedido? O pagamento do seu cliente será reembolsado.",
  wantDeclineAfterApprove:
    "Tem a certeza de que quer cancelar? O pagamento do seu cliente será reembolsado.",
  videoCall: "Iniciar fluxo",

  findExpert: "Encontrar especialista",
  selectAnExpert: "seleccione um perito",
  inviteClients: "convide os seus clientes",
  selectAChat: "Seleccione um chat para iniciar o envio de mensagens",
  noChatsYet: "Ainda sem conversas",
  notHaveMessages: "Ainda não tem nenhuma mensagem",
  shareProfileToClient: "Partilhar perfil com o cliente",
  remove: "Remover",
  time: "Hora",
  timeSlots: "Faixas horárias",
  deleteOffer: "Eliminar oferta",
  myOffers: "Ofertas",

  createNewOffer: "Criar nova oferta",
  editOffer: "Editar oferta",
  successfulyBooked: "Sessão agendada com sucesso",
  close: "Fechar",
  account: "Conta",
  price: "Preço",
  priceAndLength: "Preço e duração",

  uploadAFile: "Carregar um ficheiro",
  uploadAFileError: "O ficheiro não foi carregado",
  confirm: "Confirme",
  IDLogin: "O seu email",
  myProfile: "Perfil do cliente",
  transactions: "Transacções",
  transactionHistory: "Histórico das transacções",
  noTransactions: "Ainda não tem nenhuma transacção",

  typicalSchedule: "Horário típico",
  amount: "Montante",
  status: "Estado",
  paid: "Pago",
  action: "Acção",
  description: "Descrição",
  billing: "Facturação",
  card: "Cartão",
  clients: "Clientes",

  payout: "Pagamentos",
  firstName: "Primeiro nome",
  lastName: "Apelido",
  skip: "Saltar",
  connect: "Ligar",
  about: "Sobre",
  title: "Título",
  languages: "Línguas",
  currency: "Moeda",
  addYourOffer: "Adicione a sua oferta",
  join: "Junte-se a",
  joinIn: "Junte-se a",
  joinOn: "Junte-se a",
  message: "Mensagem",
  client: "Cliente",
  expert: "Perito",
  Expert: "Perito",
  noMoreOptions: "Não há mais opções",
  male: "Sexo masculino",
  female: "Feminino",
  custom: "Personalizado",
  copyYourLink: "Copie o seu link",
  done: "Feito",
  search: "Pesquisa",
  searchWaylight: "Encontre o seu Waylight",
  suggested: "Sugerido",

  startASession: "Sessão rápida",
  quickSession: "Sessão rápida",
  rate: "Taxa",
  perHour: "por hora",
  packages: "Pacotes",
  timezone: "Fuso horário",
  book: "Livro",
  favorite: "Favorito",
  lastSession: "Última interacção",
  ago: "Ago",
  gender: "Género",
  loading: "Carregamento",
  min: "Min",
  hour: "Hora",
  online: "Em linha",
  offline: "Offline",
  date: "Data",
  displayName: "Nome de exibição",
  closePanel: "Fechar painel",
  goToStripe: "Abra o seu painel de pagamentos",
  completeStripeOnBoarding: "Stripe Express completo a bordo",
  stripeOnBoarding: "Riscas Onboarding",
  completed: "concluído",
  notCompleted: "não concluído",
  myClients: "Os meus clientes",
  findClients: "Não conseguimos encontrar clientes",
  clientDisplayedHere: "Quando encontrar clientes, eles serão exibidos aqui",
  writeSomething: "Introduza algo e prima enter para criar o item",

  registerAs: "Registe-se como:",
  addedCards: "Os seus cartões",
  cardType: "Tipo de cartão",
  billingHistory: "Histórico de facturação",
  deleteCard: "Apagar cartão",
  wantToDeleteCard: "Tem a certeza de que quer apagar o cartão?",
  selectPaymentMethod: "Seleccione o método de pagamento",
  addNewCard: "Adicionar novo cartão",
  emailAddress: "Correio electrónico público",
  phoneNumber: "Número de telefone",
  publicPhoneNumber: "Número de telefone público",
  username: "Nome de utilizador",
  socialProfilesOrLinks: "Perfis sociais / Outros links",
  newOffer: "Nova oferta",
  myExperts: "Os meus peritos",
  myClientProfile: "O perfil do meu cliente",
  noExpertsFound: "Não foram encontrados peritos",
  openMenu: "Menu aberto",
  becomeAnExpert: "Junte-se ao Waylight Business",
  switchToExpert: "Mudar para a visão empresarial",
  switchToClient: "Mudar para a visão do cliente",
  notHaveExperts: "Ainda não tem nenhum especialista",
  endCall: "Parar o fluxo",
  endSharing: "Parar de partilhar",
  lastOnline: "Último online",
  clientSince: "Cliente desde",
  welcome: "Bem-vindo",
  messageError: "Campo obrigatório",
  support: "Apoio",
  profile: "Perfil",
  portfolio: "Portfólio",
  publicId: "Identificação pública",
  files: "Ficheiros",
  reason: "Justificação",
  declineReason: "Diga ao seu cliente porque tem de rejeitar",
  rejectionReason: "Motivo de rejeição",
  business: "Empresas",
  passwordResetInvalidEmail: "O email é inválido",
  logIn: "Iniciar sessão",
  logInSubtitle:
    "Para realizar quaisquer acções, por favor, inicie sessão ou registe-se.",
  enterEmail: "Introduzir email",
  enterPassword: "Senha",
  capsLockIsOn: "O Caps Lock está ligado",
  numLockIsOn: "Num Lock está ligado",
  forgotPassword: "Esqueceu-se da sua palavra-passe?",
  forgotPasswordEmail:
    "Introduza o seu endereço de correio electrónico para obter uma ligação de redefinição de palavra-passe.",
  requestPasswordReset: "Pedir redefinição de senha",
  requestResetLink: "Reenviar link",
  in: "Em",
  sec: "Sec",
  passwordResetRequestReceived:
    "Pedido de redefinição de palavra-passe recebido",
  passwordResetIfAccountExist:
    "Se existir uma conta, em breve receberá um email com um link.",
  resetPassword: "Redefinir palavra-passe",
  passwordChangeSuccessful: "Senha alterada com sucesso",
  passwordChangeSuccessfulSubtitle:
    "Pode agora utilizar a sua nova palavra-passe para iniciar sessão na sua conta.",
  setNewPassword: "Definir nova senha para entrar na sua conta",
  passwordChange: "Alterar palavra-passe",
  passwordOld: "Palavra-passe antiga",
  passwordNew: "Nova palavra-passe",
  dontHaveAccount: "Não tem uma conta?",
  alreadyRegistered: "Já teve uma conta?",
  tokenExpired: "Ficha expirou",
  tokenExpiredDescription: "Esta ficha já expirou ou foi utilizada antes",
  validLength: "8 ou mais caracteres",
  upperCase: "Letras maiúsculas (A-Z)",
  lowerCase: "Letras minúsculas (a-z)",
  numbersOrSymbols: "Números ou símbolos (0-9!@#$%^&*)",
  joinFree: "Adira de graça",
  businessTitle: "Waylight | Ferramenta Link in BIO que ajuda você a ganhar!",
  businessDescription:
    "Ganhe 10 vezes mais na sua receita das redes sociais! Lance em apenas 2 minutos!",
  spotlightYourExperience:
    "Ferramentas abrangentes para os seus conteúdos, especialistas e fontes de receitas comunitárias",
  monetizationPlatform: "Estúdio de Negócios Online",
  createAccount: "Criar conta",
  watchVideo: "Ver vídeo",
  bestToolsAvailable:
    "Queremos que tenha à sua disposição as melhores ferramentas e não lhe cobraremos pela sua utilização.",
  schedulingManagement: "Gestão de horários.",
  chooseConvenientTimeSlots:
    "Escolher faixas horárias convenientes para o trabalho e obter reservas.",
  expertLandingPayouts: "Pagamentos.",
  simpleOnboarding: "Simples a bordo das suas contas financeiras.",
  industryStandard:
    "Qualidade e disponibilidade de chamadas padrão da indústria.",
  lead: "Chumbo",
  engage: "Envolver",
  frequentlyAskedQuestions: "Perguntas mais frequentes",
  contactUs: "Contacte-nos:",
  accordionLabel0: "Se o serviço é gratuito, como funciona o Waylight?",
  accordionText0:
    "Estamos a receber uma comissão baseada no volume de encargos de peritos e a descoberta e as chamadas de caridade são por nossa conta!",
  accordionLabel1: "Suporta múltiplos tipos de ofertas?",
  accordionText1:
    "Absolutamente, pode criar tantas ofertas quantas quiser, especificando o preço, duração e detalhes de cada oferta.",
  accordionLabel2: "Como é que sou pago?",
  accordionText2:
    "Estamos a utilizar Stripe e outros portões para tratar dos pagamentos. É necessário um simples onboarding para ligar a sua conta bancária ou método de pagamento, que funciona em mais de 55 países. Estão a caminho vários outros métodos de pagamento e configurações flexíveis de pagamento.",
  accordionLabel3: "Posso usar o Zoom, Meet ou Skype?",
  accordionText3:
    "Temos uma solução de vídeo totalmente integrada, construída para garantir um fluxo seguro para ambas as partes. A utilização de outras soluções de vídeo com menos segurança, mas com mais flexibilidade, será uma opção no futuro.",
  accordionLabel4: "Posso usar Zapier, Calendly, etc.?",
  accordionText4:
    "Estamos a trabalhar activamente num backlog para as integrações que os nossos utilizadores mais desejam. Teremos todo o prazer em ouvir as suas ideias sobre este assunto. Envie-nos um email rápido para support@waylight.me. Obrigado!",
  freeAudience: "Nós desenvolvemos - você cria!",
  businessProfile: "Perfil do negócio.",
  onlineBusiness:
    "Crie negócios online em minutos, destaque o que faz de melhor.",
  paidCalls: "Chamadas áudio e vídeo pagas.",
  paidChatsAndBlog: "Chats e blogs pagos.",
  setUpSubscription: "Estabelecer assinatura para chat e blog pessoal.",
  web3: "Avaliações e revisões da Web3.",
  collectRealRatings:
    "Recolha avaliações e revisões reais para construir a sua marca pessoal.",
  getStartedToday: "Três passos simples para um novo fluxo de receitas:",
  createProfile: "1. Crie o seu perfil",
  fillTemplate:
    "Preencha um modelo pré-construído do seu perfil, agenda, e ofertas, estabeleça preços para chat e blogue pagos e dentro de minutos estará pronto para aceitar encomendas e subscritores.",
  shareIt: "2. Partilhá-lo com o mundo",
  linkYourProfile:
    "Ligue o seu Perfil a todos os seus canais - as suas redes sociais, anúncios, email, e muito mais. Partilhe informação sobre o seu negócio online em posts, histórias, vídeos, mensagens pessoais, etc.",
  growYourBusiness: "3. Faça crescer o seu negócio",
  shareYourExpertise:
    "Partilhe a sua experiência e conhecimentos, actualize o seu blogue, responda a reservas e chats, faça consultoria em vídeo online, e obtenha as suas classificações e críticas públicas na Web3.",
  businessflow: "Fluxo",
  businessflowStep1Title: "Adicionar link em BIO",
  businessflowStep1:
    "Adicione o seu link de perfil Waylight Business nas redes sociais BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, etc.) e partilhe informação através de histórias e posts sobre o mesmo.",
  businessflowStep2Title: "Videochamadas 1:1, chats 1:1, Blog pessoal",
  businessflowStep2:
    "Rentabilize através de chamadas de vídeo 1:1, conversas 1:1 com os seus assinantes e clientes. Crie conteúdos apenas para membros no seu blog pessoal com tutoriais, artigos, conselhos em vídeo, bastidores, experiências de vida pessoal, lições, música, etc.",
  businessflowStep3Title: "Ser pago",
  businessflowStep3:
    "Seja pago pelas suas videochamadas, chats, e subscrições apenas para membros no seu blog pessoal. Aumente a sua monetização ao partilhar mais informação sobre o seu Waylight!",
  bookOnboardingCall: "Reservar uma chamada gratuita a bordo",
  talkToFounders: "Diga aos fundadores como começar a monetizar com Waylight.",
  orScanQR: "Ou scan QR",
  yourFreeTools: "As suas ferramentas gratuitas",
  features: "Características",
  personalLink: "URL pessoal",
  personalQR: "QR Pessoal",
  scheduling: "Agendamento",
  bookings: "Reservas",
  paidChats: "Conversas 1:1 pagas",
  personalPaidBlog: "Blog pessoal pago",
  soon: "Em breve",
  subscriptions: "Assinaturas",
  payments: "Pagamentos em mais de 100 países",
  payouts: "Pagamentos em mais de 58 países",
  cryptoPayments: "Pagamentos criptográficos",
  coinsAwarding: "Atribuição de moedas",
  web3Ratings: "Avaliações Web3, revisões",
  clientsManagement: "Gestão de clientes",

  dashboard: "Painel de instrumentos",
  businessCalculator: "Calculadora de rendimentos Waylight Business",
  howMuchMoney:
    "Descubra quanto dinheiro os assinantes trariam para o seu negócio.",
  estimateNumber: "1. Estimativa do número de clientes e subscritores",
  howMuchYouCharge:
    "2. Quanto se pode cobrar por uma chamada de vídeo ou uma assinatura?",
  monthlyIncome: "Rendimento mensal",
  newToday: "Novo hoje",

  atThisWeek: "Esta semana",
  welcomeToWaylight: "Bem-vindo ao Waylight!",
  welcomeToWaylightBusiness: "Bem-vindo ao Waylight Business!",
  linkToVideo: "Link para vídeo introdutório",
  writeSentences: "Escreva algumas frases a seu respeito.",
  emptyExpertTitle: "Parece que esta página não existe",
  emptyExpertText:
    "A ligação pode estar quebrada, ou a página tenha sido desactivada ou removida.",
  profileData: "Dados de perfil",
  headerImageTooltip:
    "As dimensões da imagem de cabeçalho sugeridas são cerca de 10:1. Pode tentar algo como 1900x190px ou 1280x128px.",
  emailTooltip:
    "Este é um endereço de correio electrónico para exibição pública (não o seu login). Pode especificar qualquer email que queira ou retirá-lo completamente, é opcional!",
  timezoneTooltip:
    "Se não conseguir encontrar a sua cidade, tente procurar a zona, como 'Hora de Verão do Pacífico' ou 'Hora da Europa de Leste'.",
  linkToVideoPresentation: "Adicionar um link externo",
  expertProfileVideoPresentation: "Ou fazer o upload de um arquivo",
  linkToVideoPresentationTooltip:
    "Estabeleça uma ligação para um vídeo externo para fazer parte dos seus detalhes. A maioria dos serviços de alojamento de vídeo existentes são suportados. Se carregar um vídeo - será dada prioridade sobre esta ligação.",
  linkToVideoOfferTooltip:
    "Estabelecer uma ligação para um vídeo externo para fazer parte dos detalhes da oferta. A maioria dos serviços de alojamento de vídeo existentes são suportados. Se carregar um vídeo - será dada prioridade sobre esta ligação.",
  phoneTooltip:
    "Este é um número de telefone para exibição pública. É opcional!",
  socialProfilesTooltip:
    "Adicione ligações aos seus perfis sociais e sites, como Youtube, Facebook, Linkedin, Instagram ou Tiktok.",
  videoPresentationTooltip:
    "Carregue um vídeo para ser exibido nos seus detalhes. Irá anular o link do vídeo acima.",
  videoOfferTooltip:
    "Carregar um vídeo para ser exibido nos detalhes da oferta. Irá anular o link do vídeo acima.",
  occupationTooltip:
    "Conte ao mundo sobre a sua ocupação, por exemplo, 'AR Consultant' ou 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Adicione as suas competências para que os seus clientes saibam em que é bom, por exemplo, 'Empreendedorismo' ou 'Criação de Filhos'.",
  aboutTooltip:
    "Adicione uma descrição pessoal para que os seus clientes se sintam bem-vindos e apreciem a sua marca pessoal.",
  speaksTooltip: "Especifique as línguas que fala.",
  cropPhoto: "Fotografia de corte",
  updatePhoto: "Actualizar foto de perfil",
  crop: "Culturas",

  videoAudioSettings: "Definições de vídeo e áudio",
  videoAudioSettingsDescription:
    "Use estas definições para ajustar o vídeo e o áudio",
  cameras: "Câmara",
  microphones: "Microfone",
  output: "Oradores",
  bgSettings: "Escolha um fundo de vídeo",
  toStartAChat: "Para iniciar uma conversa,",
  startAChat: "E iniciar uma conversa.",
  messageToAnExpert: "Envie a sua primeira mensagem a um especialista",
  messageToAClient: "Envie a sua primeira mensagem a um cliente",
  chatWindowGetToKnow:
    "1. Conheça os seus potenciais clientes e as suas necessidades",
  requestInformation: "2. Pedir informações adicionais, se necessário",
  notGettingAReply:
    "3. Se não receber uma resposta, pode tentar acompanhar educadamente",

  chatWindowProvideABrief: "1. Forneça uma breve descrição do que procura",
  chatWindowAddAttachments: "2. Acrescentar anexos, se necessário",
  describeYourIssue:
    "Por favor descreva o seu pedido e o que pretende aprender ou com o que precisa de ajuda. Anexe quaisquer ficheiros adicionais, se necessário.",
  getAConfirmation: "Receberá em breve uma confirmação do perito.",
  describeTheDetails:
    "Descreva os detalhes, preço e pré-requisitos para a sua oferta.",
  areYouSure: "Tem a certeza de que quer eliminar esta oferta?",
  offerAvailable: "Oferta disponível",
  offerUnavailable: "Oferta não disponível",
  notHaveOffers: "Ainda não tem nenhuma oferta",
  createOne: "Crie um para começar a trabalhar com os seus clientes.",
  getPaid: "É-lhe pago o preço especificado por um montante fixo de tempo.",
  describeYourOffer: "Descreva a sua oferta em breves detalhes.",
  priceLimit: "O preço pode ser $0 ou de ${{minPrice}} a ${{maxPrice}}.",
  priceLimitForUA: "O preço pode ser ₴0 ou de ₴{{minPrice}} a ₴{{maxPrice}}.",
  offerNameDescription:
    "Tente algo como 'Discovery Call' ou 'Basic Graphic Design' se isso for a sua coisa.",
  clickingConfirm: "Ao clicar em 'Confirmar', concordo com ...",
  termsAndConditions: "Termos e Condições",

  paymentFeesMayAlter: "As taxas do sistema de pagamento podem variar.",
  notBeCharged: "Você nunca será cobrado sem confirmação adicional.",
  last: "Últimos 4",
  lastDigits: "Últimos 4 dígitos",
  billingPageName: "Nome",
  gateway: "Porta de entrada",
  prepareForTheMeeting: "Preparar para a reunião",
  prepareForSession: "Preparar para",
  findAQuiet: "Encontre um lugar tranquilo onde ninguém o incomode",
  makeSure: "Certifique-se de que tem uma boa ligação à Internet",
  weRecommendUsing: "Recomendamos o uso de auscultadores",
  prepareYourQuestions: "Prepare as suas perguntas",

  initialization: "Inicializando...",
  beginOnboardingAsExpert:
    "Começar a embarcar como especialista em Waylight Business",
  connectStripeAccount:
    "A fim de receber dinheiro dos seus clientes, é necessário criar um método de pagamento.",
  provideABrief: "1. Forneça uma breve descrição do que procura",
  addAttachments: "2. Acrescentar anexos, se necessário",
  gettingAReply:
    "3. Se não receber uma resposta, pode tentar acompanhar educadamente",

  fileType: "O tipo de ficheiro não é suportado",
  orDragAndDrop: "Ou arrastar e largar",
  upTo: "até 1 GB (entre em contato com o suporte se tiver tamanhos de arquivo maiores)",
  headerImage: "Imagem de cabeçalho",
  inviteClientsModalInviteClients: "Convidar clientes",
  shareYourLink:
    "Partilhe a sua ligação pessoal para convidar os seus clientes!",
  addDays: "Configure o seu horário regular",
  publiclyInformation:
    "Podem ser acrescentadas várias faixas horárias por dia da semana.",
  availableTimeslot: "Tempo disponível",
  AddTimeslot: "Acrescentar o intervalo de tempo",
  AddToSchedule: "Adicionar ao horário",
  signUpAs: "Inscreva-se como",
  registerAsClient: "Quero aprender algo novo",
  registerAsExpert: "Quero partilhar a minha experiência",
  noTimeslots:
    "Não há faixas horárias disponíveis. Escolher outra data ou perguntar ao perito sobre o horário.",
  toastSavedSuccessfully: "Salvo com sucesso",
  toastDeletedSuccessfully: "Eliminado com sucesso",
  toastEmailCopied: "Email copiado",
  toastProfileCopied: "Perfil copiado",
  toastBookingAvalable: "Está agora disponível para reserva",
  toastBookingUnavalable: "Não está disponível para reserva",
  toastQrCopied: "Código QR copiado",
  toastPaymentMethodAdded: "Método de pagamento adicionado com sucesso",
  toastPaymentMethodDeleted: "Método de pagamento eliminado com sucesso",
  toastUrlCopied: "Ligação pessoal copiada",
  toastOfferUrlCopied: "Link de oferta copiado",

  toastPayoutMethodAdded: "Método de pagamento adicionado com sucesso",
  toastPayoutMethodDeleted: "Método de pagamento eliminado com sucesso",
  toastPrimaryCardError: "Não foi possível definir o cartão primário",
  toastPrimaryCardAdded: "Cartão primário adicionado com sucesso",
  toastTooLargeFile: "O tamanho do ficheiro é demasiado grande",
  toastOfferNotAdded: "A oferta não foi acrescentada",
  toastOfferNotEdited: "A oferta não foi editada",
  toastSessionLinkCopied: "Link da sessão copiado",
  toastMaxParticipantsReached: "Número máximo de participantes atingido",
  toastLinkExpired: "Esta ligação já expirou.",

  toastSessionExtendedSuccessfully: "A sessão foi prolongada com sucesso",
  toastTimeslotStartAndEnd:
    "Não se pode adicionar um intervalo de tempo que começa e termina ao mesmo tempo",
  toastTimeslotEndsInPast:
    "Não se pode acrescentar um intervalo de tempo que termina no passado",
  toastTimeslotDuplicate:
    "Não se pode acrescentar um espaço de tempo duplicado",
  toastSkillNameTooLong:
    "O seu nome de habilidade é demasiado longo, tente escolher um nome mais curto",
  buttonSave: "Guardar",
  buttonCancel: "Cancelar",
  buttonApprove: "Aprovar",
  buttonDelete: "Eliminar",
  buttonChat: "Bate-papo",
  buttonOpenChat: "Bate-papo",
  buttonFiles: "Ficheiros",
  buttonNotNow: "Agora não",
  buttonSubmit: "Submeter",
  buttonNo: "Não",
  buttonEnd: "Fim",
  buttonSignUp: "Inscrever-se",
  buttonYes: "Sim",
  buttonEdit: "Editar",
  buttonCopy: "Cópia",
  buttonReply: "Resposta",
  buttonAdd: "Acrescentar",
  buttonSend: "Enviar",
  buttonRequest: "Pedir apoio",
  buttonSet: "Conjunto",
  buttonEditPhoto: "Editar foto",
  buttonStartAnyway: "Comece de qualquer maneira",
  buttonWait: "Espere",
  buttonCopyLink: "Link para cópia",
  buttonLeave: "Sair",
  buttonExtraMinutes: "10 minutos extra",
  buttonViewOffer: "Ver detalhes",
  buttonMoreDetails: "Mais detalhes",
  buttonContinue: "Continuar",
  buttonBookACall: "Marcar uma chamada",
  notificationSetup: "Configuração",
  notificationFillProfile: "Perfil de preenchimento",
  notificationHi: "Hi!",
  notificationAddYourName:
    "Adicione o seu nome e fotografia para animar a comunicação, se lhe apetecer. Sem pressão :)",
  notificationSetupBilling: "Configuração de facturação",
  notificationGoToBilling: "Ir para Facturação",
  notificationWeAreSureYouHaveALotToSay:
    "Temos a certeza de que tem muito a dizer sobre si próprio. Preencha o seu perfil e comece a partilhar!",
  notificationAddAnOffer: "Acrescentar uma oferta",
  notificationAddYourOffers: "Adicione as suas ofertas",
  notificationGoToMyOffers: "Ir para Ofertas",
  notificationConnectStripe: "Pagamentos de instalação",
  notificationSetupPayouts: "Pagamento a bordo",
  notificationInOrderToReceiveMoney:
    "A fim de receber dinheiro dos seus clientes, é necessário criar um método de pagamento.",
  notificationGoToPayouts: "Ir para Pagamentos",
  notificationSetYourSchedule: "Defina a sua agenda",
  notificationWorkAtConvenientHours: "Trabalhar em horários convenientes",
  accountOn: "Conta em",
  accountOff: "Conta desligada",

  paymentFailed: "O pagamento falhou",

  areYouSureCancelPaidSession:
    "Tem a certeza de que quer cancelar? O seu pagamento será reembolsado.",
  areYouSureCancelLessThanADay:
    "Tem a certeza de que quer cancelar com menos de 24 horas de antecedência? O seu pagamento NÃO será reembolsado.",
  link: "Ligação",
  view: "Ver",
  requires_action: "Requer acção",
  succeeded: "Foi bem sucedido",
  signUpTitle: "Inscrever-se",
  profileTitleNotify: "Perfil | Waylight",
  dashboardTitleNotify: "Painel de instrumentos | Waylight",
  chatTitleNotify: "Bate-papo | Waylight",
  with: "com",
  expertsTitle: "Peritos",
  expertsTitleNotify: "Explorar | Waylight",
  signUpTitleNotify: "Inscrição | Waylight",
  chatTitle: "Bate-papo",
  clientsTitle: "Clientes",
  clientsTitleNotify: "Clientes | Waylight",
  clientTitle: "Cliente",
  fixedPrice: "Preço fixo",
  internetConnectionLost: "Perda de ligação à Internet",
  fiveMinutesLeft: "Faltam 5 minutos",
  summarizeMeeting:
    "Por favor resuma a sua reunião e partilhe as suas impressões",
  oopsConnectionLost: "Oops... Perda de ligação à festa",
  partyTemporaryLostConnection:
    "Parece que o seu partido perdeu temporariamente a ligação à reunião. Lamentamos o incómodo.",
  leaveAReviewFor: "Deixar uma revisão para",
  leaveAReview: "Deixar uma revisão",
  review: "Revisão",
  followers: "Seguidores",
  rating: "Classificação",
  hitStarRate: "Avalie a sessão abaixo!",
  reviewsEmpty: "Ainda não há revisões",
  reviews: "Comentários",

  thankYouForLighting: "Obrigado por iluminar o caminho de alguém!",
  sessionNotStarted: "A sessão ainda não começou",
  devicePermissionTittle:
    "Permita que o Waylight aceda ao seu microfone e câmara fotográfica. Actualize a página se não tiver vídeo.",
  networkConnection: "A sua qualidade de ligação à Internet é",
  unknown: "Desconhecido",
  low: "Baixo",
  average: "Média",
  good: "Bom",
  expertDidNotCome:
    "Infelizmente, o seu perito não apareceu. Não lhe será cobrado e receberá um reembolso. Pedimos desculpa pelo incómodo.",
  noFilesAdded: "Nenhum ficheiro adicionado.",
  enterYourEmail: "Introduza o seu endereço de correio electrónico",
  required: "O campo é obrigatório",
  emailOrPassword: "Correio electrónico ou palavra-passe incorrectos",
  emailInUse: "Este email já está a ser utilizado",
  businessIsNotAvailableTitle:
    "O Waylight Business não está disponível no seu país. Seja o primeiro a saber quando é lançado.",
  beInTouch: "Esteja em contacto",
  businessIsNotAvailableEmailAddress: "Endereço de correio electrónico",
  pleaseTryAgain:
    "Não conseguimos guardar o seu endereço de correio electrónico. Por favor, tente novamente.",
  thankYou: "Obrigado, entraremos em contacto assim que isto se concretizar.",

  yourCountry: "O país do seu banco",
  open: "Aberto",
  offerName: "Nome da oferta",
  gatewayCountryWarning:
    "Aviso: este deve ser o país da sua instituição bancária e NÃO pode ser alterado posteriormente, o que pode afectar a sua capacidade de receber pagamentos.",
  addAttachment: "Adicionar anexo",
  you: "Você",
  explore: "Explorar",
  stats: "Estatísticas",
  more: "Ver mais",
  noPrice: "Ofertas gratuitas",
  theyWillAppear: "Eles aparecerão aqui quando começar a interagir",
  noExpertFoundUsingFilter:
    "Não foram encontrados peritos a utilizar este filtro.",
  tryAnotherInput: "Tente outra etiqueta ou input.",
  noFavoriteExpertsFound: "Não foram encontrados peritos favoritos.",
  tryAddingSomeone: "Tente adicionar alguém aos favoritos.",
  agoraDeniedError:
    "Por favor, habilite o seu acesso ao microfone e à câmara fotográfica em configurações a fim de utilizar o Waylight!",
  editCover: "Editar capa",
  language: "Idioma",
  invalid: "Inválido",
  typeHere: "Escreva aqui",
  schedule: "Horário",
  offers: "Ofertas",
  qrSharing: "Descarregue e partilhe o seu código QR.",
  qrSharingMobile:
    "Prima durante muito tempo o código QR para o copiar e partilhar.",
  preview: "Pré-visualização",
  back: "Voltar",
  love: "Feito com amor por Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Todos os direitos reservados.",
  allRightsReservedSecond:
    "© 2022 Arceon Consulting OÜ. Todos os direitos reservados",
  termsOfService: "Termos de Serviço",
  privacyPolicy: "Política de Privacidade",
  sessionSharingpopupTitle: "Não é possível iniciar a partilha de ecrã!",
  sessionSharingpopupDescription:
    "Verifique se tem uma versão recente do seu navegador e se este tem permissões de sistema para partilha de ecrã. Finalmente, pode tentar refrescar a página. Se o problema persistir, por favor contacte o suporte!",
  sessionSharingpopupSafariDescription:
    "A partilha de ecrã não é suportada no seu navegador. As versões mais recentes dos browsers populares suportam esta função.",
  copy: "Cópia",
  expertWithoutOffers: "Este perito ainda não acrescentou nenhuma oferta paga.",
  errorViewHeader: "Oops, algo correu mal!",
  errorViewDescription:
    "Encontrou um erro. Esta ligação pode estar quebrada, ou não tem acesso a um item.",
  returnToHome: "Regresso a casa",
  invalidURL:
    "URL inválido. Certifique-se de que começa com 'https://' ou 'http://'.",
  invalidVideoExternalURL:
    "URL de vídeo inválido. Adicionar ou um link para o Youtube ou para o Vimeo.",
  repeatedURL: "Esta ligação já foi acrescentada",
  tooLongUrl:
    "Esta ligação é demasiado longa. Por favor, não utilize as ligações com mais de 128 caracteres.",
  edited: "Editado",
  emptyCards: "Ainda não adicionou nenhum cartão",
  emptyBillingHistory: "Ainda não tem nenhuma transacção",
  addNewSkill: "Acrescentar novas competências",
  confirmationAddSkill:
    "Tem a certeza de que quer acrescentar novas competências?",
  addPaymentMethod: "Adicionar método de pagamento",
  stripeDescription: "Para transacções com peritos de mais de 50 países",
  fondyDescription: "Para transacções com peritos da Ucrânia",
  fondyAddCardDescription:
    "Acrescentar um método de pagamento para prosseguir. Será tentada uma taxa de teste para confirmar.",
  timeZoneChangedHeader: "Fuso horário alterado",
  timeZoneSChangedDescription:
    "Detectámos que o seu fuso horário mudou. Gostaria de actualizar o seu fuso horário de perfil?",
  updateButton: "Actualização",
  and: "e",
  moreFiles: "mais ficheiros",
  addPayout: "Acrescentar pelo menos um método para receber pagamentos",
  testChargeWarning: "Será tentada uma taxa de teste para confirmar.",
  payoutMethods: "Os seus métodos de pagamento",
  identifier: "Identificador",
  stopWar: "Parar a guerra",
  yourAreOnlyOneHere: "Você é o único aqui",
  yourAreOnlyOneHereSingle:
    "É o único aqui. Partilhe o link abaixo para que outros possam aderir",
  clientView: "Cliente",
  businessView: "Empresas",
  mainPage: "Página principal",
  signUpCompleted: "Inscrição concluída",
  selectPaymentInfo:
    "Os métodos de pagamento disponíveis dependem da porta de entrada do seu perito",
  payment: "Pagamentos",
  paymentWasDeclined: "O pagamento foi recusado.",
  payoutWasDeclined: "O pagamento foi recusado.",
  primary: "Primário",
  notUsed: "Não utilizado",
  releaseBannerTitle: "A Waylight foi actualizada para uma nova versão.",
  refresh: "Actualizar",
  country: "País",
  viewAll: "Ver tudo",
  videoPresentation: "Apresentação de vídeo",
  bookFor: "Livro para",
  bookForFree: "Livro de graça",
  follow: "Siga",
  followed: "Seguiu-se",
  emptyPortfolio: "Parece que não há ficheiros nesta carteira",
  media: "Meios de comunicação",
  other: "Ficheiros",
  emptyReview: "Parece que não há revisões",
  newest: "O mais recente",
  oldest: "O mais antigo",
  viewProfileInfo: "Ver informações de perfil",
  emptyTransactions: "Parece que não há transacções",
  emptyOffers: "Infelizmente, este perito ainda não tem quaisquer ofertas.",
  searchExperts: "Pesquisar peritos",
  modifySchedule: "Modificar o horário",
  editMode: "Modo de edição",
  finishEditing: "Acabar a edição",
  editPersonalInfo: "Editar informação pessoal",
  editPortfolio: "Editar portfólio",
  profileId: "Identificação pública pessoal",
  profileSettings: "Definições de perfil",
  editPublicID: "Editar identificação pública",
  removeTimeslot: "Retirar o intervalo de tempo",
  areYouSureRemoveTimeslot:
    "Tem a certeza de que quer remover o espaço de tempo?",
  qrCode: "Código QR",
  communication: "Comunicação",
  editCoverDescription: "Personalize a sua imagem de capa",
  editProfileData: "Editar dados de perfil",
  editProfileDataDescription:
    "Adicione informação sobre si e os seus antecedentes",
  editPublicIDDescription:
    "Escolha uma identificação pública única para o seu perfil",
  profilePhoto: "Foto de perfil",
  publicIDDescription: "Esta é uma identificação auto-gerada!",
  publicIDWarning:
    "Não se esqueça de o mudar para algo fresco no modo de edição, como 'supergirl'.",
  followWarning: "O botão 'Siga' funcionará para todos os utilizadores.",
  openChatWarning:
    "Não poderá ter uma conversa consigo mesmo, mas os seus clientes terão.",
  bookingOfferWarning: "Os usuários poderão comprar uma oferta clicando aqui.",
  shareOffer: "Link para cópia",
  turnOffAccount: "Desligar conta",
  turnOnAccount: "Turn on account",
  areYouSureYouWantTurnOff:
    "Tem a certeza de que quer desligar completamente a sua conta?",
  areYouSureYouWantTurnOn:
    "Tem a certeza de que quer voltar a ligar a sua conta?",
  disableFreeChat: "Desactivar chat grátis",
  enableFreeChat: "Activar o chat gratuito",
  areYouSureYOuWantDisableFreeChat:
    "Tem a certeza de que quer desactivar o chat gratuito?",
  areYouSureYOuWantEnableFreeChat:
    "Tem a certeza de que quer permitir um chat gratuito?",
  cancelEditing: "Cancelar edição",
  descriptionCancelEditing: "Tem a certeza de que quer cancelar a edição?",
  takenPublicID: "Esta identificação pública já foi tirada",
  charactersMatch: "2-64 caracteres",
  charactersBetweenMatch: "O campo deve ter de 1 a 64 caracteres",
  charactersMaxMatch: "O comprimento máximo do campo é de 64 caracteres",
  deletePhoto: "Apagar foto",
  descriptionDeletePhoto: "Tem a certeza de que quer apagar a sua fotografia?",
  accountOnDescription:
    "Quando a conta é desligada, não está disponível e não é visível por qualquer meio (ligações directas, resultados de pesquisa, etc.)",
  freechat: "Chat grátis",
  freechatDescription:
    "Quando o chat gratuito for desactivado, os utilizadores não poderão iniciar a comunicação consigo por este meio. No entanto, poderá fazê-lo com os seus clientes.",

  editMessage: "Editar mensagem",
  replyTo: "Responder a",
  file: "Ficheiro",
  backgroundWarning:
    "Aviso: os fundos não são totalmente suportados nos navegadores Safari e Firefox e podem não funcionar.",
  single: "Rápido",

  connectIn: "Ligar em",
  connectOn: "Ligar em",
  sessionDecisionTittle: "A sua festa ainda não apareceu.",

  cancelledSessionExpertDescription:
    "O pagamento foi transferido para a sua conta. Pedimos desculpa pelo inconveniente.",
  cancelledSessionClientDescription:
    "Não lhe será cobrado e receberá um reembolso. Pedimos desculpa pelo inconveniente.",

  addExtraMinutes: "Acrescentar 10 minutos extra",

  unableAddExtraMinutes: "Incapaz de adicionar 10 minutos extra",

  monday: "Segunda-feira",
  tuesday: "Terça-feira",
  wednesday: "Quarta-feira",
  thursday: "Quinta-feira",
  friday: "Sexta-feira",
  saturday: "Sábado",
  sunday: "Domingo",
  h: "h",
  sharingSetting: "Partilha",
  call: "Chamada",
  public: "Público",
  byLinkOnly: "Apenas por link",
  offerInfo: "Informação sobre a oferta",
  coverVideo: "Capa / vídeo",
  imageVideo: "Imagem / vídeo",
  chooseCountry: "Escolha o país",
  passwordChangedSuccessfully: "Senha alterada com sucesso",
  connected: "Ligado",
  passedALot: "Aprovado",
  chooseCountryBank: "Escolha o país do seu banco ou serviço financeiro.",
  createAccountContinue: "Criar conta para continuar",
  metasignInTitle: "Log-in | Waylight",
  metasignUpTitle: "Inscrição | Waylight",
  metapasswordForgotTitle: "Senha esquecida | Waylight",
  metapasswordResetTitle: "Redefinir palavra-passe | Waylight",
  clientflowStep1Title: "Encontrar ligação na pesquisa BIO ou Waylight",
  clientflowStep1:
    "Encontre o link Waylight do seu criador, influenciador, especialista, ou tutor em redes sociais (Instagram, Facebook, YouTube, LinkedIn, etc.) ou escreva o nome ou apelido na pesquisa Waylight.",
  clientflowStep2Title:
    "Reservar uma videochamada ou subscrever um blog ou chat",
  clientflowStep2:
    "Abra e explore o perfil empresarial do seu criador, influenciador, perito, ou tutor. Encontre uma oferta interessante para uma chamada de vídeo 1:1, reserve-a e salte sobre ela à hora marcada. Tente conversar depois ou antes para obter mais informações a longo prazo. Subscreva o blog pessoal apenas para membros com tutoriais, artigos, conselhos em vídeo, bastidores, experiências de vida pessoal, lições, música, etc.",
  clientflowStep3Title: "Verificar classificações e revisões descentralizadas",
  clientflowStep3:
    "Avaliações descentralizadas constroem um historial para criadores e peritos, não podem ser vendidas ou falsificadas e criam um sentimento de confiança, transparência, prova de perícia, e procura de conteúdos para clientes e assinantes. Deixe as suas verdadeiras classificações após videochamadas, conversas e sob a forma de posts só para membros em blogs. Partilhe as suas críticas para ajudar a comunidade Waylight a dar destaque às melhores.",
  confirmBooking: "Confirmar seleção",
  free: "Grátis",
  unableVerifyEmail: "Não nos foi possível verificar o seu email.",
  thankYouForVerifiedEmail: "Obrigado por verificar o seu email!",
  reSend: "Reenviar",
  signInToContinue: "Iniciar sessão para continuar",
  switchingToClientView: "Mudança para a visão do cliente",
  switchingToExpertView: "Mudança para a visão empresarial",
  cookiePermission:
    "Agradecemos a sua privacidade. Utilizamos cookies para melhorar a sua experiência de navegação, servir conteúdos personalizados, e analisar o nosso tráfego. Ao clicar em 'Aceitar', consente a nossa utilização de cookies.",
  readMore: "Leia mais",
  notReadableDevicesWarning:
    "Aviso: a sua câmara e/ou microfone está actualmente em uso ou não pode ser acedido!",
  howPayoutsWork: "Como é que os pagamentos funcionam?",
  payoutsOnboarding1: "Completar o onboarding para permitir pagamentos.",
  payoutsOnboarding2:
    "Mantemos o seu dinheiro a salvo até à sua entrada a bordo.",
  payoutsOnboarding3:
    "Necessitará dos seus dados bancários ou dados do cartão, dependendo do seu país.",
  payoutsOnboarding4: "Os pagamentos ocorrem todas as segundas-feiras.",
  payoutsOnboarding5: "Pode verificar o estado de pagamento em Transacções.",
  payoutsOnboarding6:
    "O gateway de pagamento cobra 0,25% + $0,25 por pagamento.",
  payoutsOnboardingEcommpay6:
    "O gateway de pagamento cobra 0,5% para cartões e 0% para comerciantes se as taxas do cliente estiverem habilitadas.",
  payoutsOnboarding7:
    "Se esta for a primeira vez que recebe o pagamento, pode demorar 7-14 dias úteis a chegar devido a limitações da porta de entrada.",
  payoutsOnboarding8:
    "Normalmente, os pagamentos demoram cerca de 3 dias úteis a chegar.",
  payoutsOnboarding9:
    "Actualmente não é possível mudar o país. Contacte-nos se precisar disto e tentaremos resolver caso a caso.",
  payoutsOnboarding10:
    "A Waylight cobra uma comissão variável com base no seu plano.",
  payoutsOnboarding11: "Se você tiver alguma dúvida, entre em contato com",
  supportAlternative: "Apoio",

  cancelledFreeSessionDescription:
    "Foi o único que apareceu. Pedimos desculpa pelo incómodo.",
  m: "m",
  cityNotFound:
    "Não foram encontradas opções. Escolher a grande cidade mais próxima.",
  notifications: "Notificações",
  telegramBot: "Telegram Bot",
  notConnected: "nãoligado",
  connectTelegramBot: "Bot Telegramas de ligação",
  disconnectTelegramBot: "Desligar o Telegrama Bot",
  telegramConnected: "conectado",
  bgSettingsDescription:
    "Use estas definições para ajustar o fundo para o seu fluxo de vídeo",
  selectBackground: "Seleccione o fundo",
  bookingDelay: "Set a booking delay",
  bookingDelayDescription: "Specify how much in advance you can be booked.",
  processingFee: "Taxa do sistema de pagamento",
  payoutsOnboardingEcommpay1:
    "Selecione o método de pagamento de cartão de crédito pessoal ou empreendedorismo privado.",
  payoutsOnboardingEcommpay3:
    "Você deve adicionar pelo menos um cartão de crédito pessoal + seu código fiscal individual OU concluir a integração do comerciante Fondy para que as transações funcionem!",
  payoutsOnboardingEcommpay4: "Os pagamentos ocorrem após cada transação.",
  payoutsOnboardingEcommpay7: "Os pagamentos ocorrem até o próximo dia útil.",
  tutorials: "Produtos",
  tutorialsAndCourses: "Comprar produtos",
  noOffers: "Parece que ainda não existem ofertas aqui",

  newTutorialOffer: "Nova oferta de produto",
  newTutorialOfferDescription: "Crie um produto pago ou gratuito",

  tutorialName: "Nome do produto",
  tutorialNameDescription:
    "Escolha um nome para o seu produto, como “Aula de Dança #1”",
  cover: "Capa",
  tutorialCoverTooltip:
    "Carregue uma imagem ou vídeo para ser exibido na descrição do produto.",
  getTutorialPaid: "Você recebe pagamento por cada compra deste produto.",
  unlockedAfterPurchase: "Desbloqueado após a compra",
  unlockedAfterPurchaseDescription:
    "Adicione conteúdo que seus clientes desbloquearão.",
  tutorialDetailsDescription:
    "Adicione comentários, detalhes ou instruções aqui",
  removeSection: "Remover seção",
  addAnotherSection: "Adicionar outra seção",
  details: "Detalhes",
  toastEmptyUnlockedContent: "O conteúdo bloqueado não pode estar vazio",
  toastTutorialNotEdited: "Este produto não foi editado",
  removeSectionDescription: "Tem certeza de que deseja remover a seção?",
  buyFor: "Comprar por",
  buttonOpenChatAlt: "Abrir chat",
  buttonShare: "Compartilhar",
  tutorial: "Produto",
  toastBuyingYourOwnTutorial: "Você não pode comprar seu próprio produto!",
  purchasingInProgress: "Transação em andamento",
  byClickingConfirm: "Ao clicar",
  iAgreeWith: "eu concordo com o",
  allowWaylightToCharge:
    "e permitir que a Waylight cobre meu cartão agora e no futuro, de acordo com os termos da assinatura, até que eu cancele.",
  paymentProcessingFees:
    "Taxas de processamento de pagamento podem ser aplicadas.",
  bookingTutorialWarning:
    "Os usuários poderão comprar um produto clicando aqui.",
  editTutorials: "Editar produtos",
  topReview: "Melhor avaliação",
  addForFree: "Adicionar gratuitamente",
  tutorialPaymentFailed:
    "Seu pagamento não foi processado e você não pôde comprar este produto.",
  buy: "Comprar",
  tutorialWith: "produto por",
  purchased: "Comprado",
  noTutorialsFound: "Nenhum produto encontrado",
  noTutorialsFoundDescription:
    "Eles aparecerão aqui quando você comprar ou adicionar um produto",
  tags: "Tags",

  createTutorial: "Criar oferta de produto",
  createTutorialDescription: "Adicione um produto pago ou gratuito.",
  requires_transfer: "Requer integração",
  pending: "Aguardando pagamento",
  requires_payout: "Aguardando pagamento",
  requires_capture: "Em espera",
  reschedule: "Reagendar",
  rescheduleDelay: "Definir atraso no reagendamento",

  buttonChange: "Reagendar",
  timeslots: "Horários disponíveis",
  specifyReason: "Especifique um motivo para a mudança de data e horário.",

  rescheduleReason: "Motivo do reagendamento",
  optionLink: "Adicionar um link externo",
  optionUpload: "Ou fazer upload de um arquivo",
  tutorialLinkDescription: "Adicione o link para o seu produto.",
  tutorialUploadDescription:
    "Faça o upload de qualquer arquivo aqui. Você pode adicionar um arquivo por seção.",
  bgNone: "Nenhum",
  bgLowBlur: "Baixo desfoque",
  bgMediumBlur: "Médio desfoque",
  bgHighBlur: "Alto desfoque",
  bgSolidColour: "Cor sólida",
  bgCustomImage: "Imagem personalizada",
  telegramNotifications: "Notificações no Telegram",
  connectYourTelegramAccount:
    "Conecte sua conta do Telegram para receber notificações da Waylight.",
  buttonConnectTelegram: "Conectar Telegram",
  notificationGoToMyProfile: "Ir para o perfil",
  setupSchedule: "Configurar o cronograma",
  aboutTheTutorial: "Sobre o produto",
  added: "Adicionado",
  orderingChangeInProgress: "Mudando a ordem...",
  gloryToUkraine: "Glória à Ucrânia!",
  terroristStates:
    "A Waylight não funciona para usuários de países sancionados com regimes ditatoriais e terroristas: Rússia, Irã, Coreia do Norte, Síria, Cuba, Venezuela, Afeganistão.",
  never: "Sem limite",
  expiration: "Limite de tempo",
  expirationDescription:
    "Os clientes terão acesso a este produto por um número específico de dias",
  daysLeft: "dias restantes",
  tutorialExpired: "Seu acesso a este produto expirou.",
  days: "dias",
  tips: "Doações",
  newTipOffer: "Nova oferta de doação",
  newTipOfferDescription: "Criar uma doação",
  tipName: "Nome da doação",
  tipNameDescription: "Escolha um nome para a sua doação",
  tipCoverTooltip:
    "Carregue uma imagem ou vídeo para ser exibido na descrição da doação.",
  aboutTheTip: "Sobre a doação",
  describeYourTip:
    "Adicione uma descrição da doação: arrecadar doações, realizar uma ação beneficente ou comprar um café?",
  tipAmounts: "Adicione três valores de doação",
  tipAmountsDescription:
    "Seus clientes também poderão inserir o próprio valor, se desejarem.",
  bonusTipper: "(Opcional) Enviar um bônus para o cliente",
  bonusTipperDescription:
    "Adicione conteúdo ou mensagem que seus clientes receberão como bônus após a doação.",
  tipLinkDescription: "Adicione o link para o seu bônus.",
  tipMessageDescription: "Adicione sua mensagem de texto aqui.",
  toastTipNotEdited: "Esta doação não foi editada",
  leaveATip: "Deixar uma doação",
  bookingTipWarning: "Os usuários poderão deixar uma doação ao clicar aqui.",
  includesAReward: "Inclui um bônus",
  selectTip: "Selecione quanto você gostaria de doar",
  selectTipAmount: "Selecione um valor para a doação",
  toastBuyingYourOwnTip: "Você não pode comprar a sua própria doação!",
  tipSuccessfullyBought: "Doação comprada com sucesso",
  tip: "Doação",
  tipPaymentFailed:
    "Seu pagamento não foi concluído e você não conseguiu comprar esta doação.",
  purchasedOffers: "Comprados",
  noTipsFound: "Nenhuma doação encontrada",
  noTipsFoundDescription: "Elas aparecerão aqui quando você comprar uma doação",
  thankYouExclamation: "Obrigado!",
  editTips: "Editar doações",
  tipPriceLimit: "O preço pode ser de ${{minPrice}} a ${{maxPrice}}.",
  tipPriceLimitForUA: "O preço pode ser de ₴{{minPrice}} a ₴{{maxPrice}}.",
  cardAuthorization: "Autorização do cartão de crédito",
  failed: "Falha",
  communityName: "Nome da comunidade",
  communityNameDescription:
    "Escolha um nome para sua comunidade, como “Meu canal exclusivo apenas para assinantes no Telegram“",
  getCommunityPaid: "Preço da assinatura",
  Period: "Período*",
  expirationCommunityDescription: "Defina a duração da assinatura",
  aboutTheCommunityOffer: "Sobre a oferta",
  TelegramChannelName: "Nome do canal/grupo do Telegram*",
  communityTelegramNameDescription:
    "Selecione o nome do seu canal ou grupo. Se estiver vazio, clique no ícone de informação para obter instruções.",
  communities: "Comunidades",
  community: "Comunidade",
  subscribeFor: "Assine por",
  subscribed: "Inscrito",
  unsubscribe: "Cancelar inscrição",
  unsubscribeDescription:
    "Tem certeza de que deseja cancelar a inscrição?\nSeu acesso expirará em",
  subscribeDescription: "{{community}}.",
  usubscribed: "Cancelado",
  unlimited: "Ilimitado",
  subscribeAgain: "Inscreva-se novamente",
  unsubscribeDescriptionModal:
    "Tem certeza de que deseja se inscrever novamente?\nVocê será cobrado pelo próximo período em",
  subscribe: "Inscreva-se",
  currentlyMember: "Você é atualmente um membro de {{title}} no Telegram.",
  notCurrentlyMember:
    "Você não é atualmente um membro de {{title}} no Telegram.",
  newCommunityOffer: "Nova oferta de comunidade",
  newCommunityOfferDescription: "Crie ou gerencie uma comunidade",
  editCommunities: "Editar comunidades",
  joinCommunities: "Participar de comunidades",
  noCommunitiesAdded: "Nenhuma comunidade adicionada",
  TheyWillAppear: "Eles aparecerão aqui quando você criar suas ofertas.",
  createCommunity: "Criar comunidade",
  noAccessToData: "Sem acesso aos dados! Adicione",
  noAccessToDataAsAnAdministrator:
    "como administrador ao seu grupo para fornecer acesso.",
  retryPayment: "Tentar novamente o pagamento",
  areYouSureYouWantToSetCard:
    "Tem certeza de que deseja definir o cartão *{{last4}} como principal?",
  thingsToTry: "Coisas para tentar:",
  checkIfYouHaveSufficient:
    "Verifique se você tem fundos suficientes em seu saldo ou se os pagamentos pela internet estão habilitados.",
  selectDifferentPaymentMethod: "Selecione um método de pagamento diferente.",
  set: "Definir",
  primaryCard: "Principal",
  setPrimary: "Definir como principal",
  inviteMember: "Convidar membro",
  copyLink: "Copiar link",
  members: "Membros",
  cancel: "Cancelar",
  actions: "AÇÕES",
  subscribedUntil: "INSCRITO ATÉ",
  waylightClients: "Cliente WAYLIGHT",
  nameMembers: "NOME",
  admin: "Administrador",
  external: "Externo",
  subscriber: "Assinante",
  unsubscribed: "Cancelado",
  kicked: "Expulso",
  owner: "Proprietário",
  save: "Salvar",
  kickAndBan: "Expulsar",
  areYouSureYouWantToRemove:
    "Tem certeza de que deseja remover “{{name}}” do seu canal?",
  WeHaveEncounteredAnIssue:
    "Encontramos um problema com seu pagamento recorrente!",
  yourPaymentHasNotGone:
    "Seu pagamento não foi concluído e não conseguimos tentar cobrar pela sua assinatura novamente.",
  gatewayFee: "Os clientes pagam taxas do gateway",
  gatewayFeeDescription:
    "Com essa configuração ativada, a taxa do gateway de pagamento será adicionada às faturas do cliente. Quando desativada, a taxa será subtraída dos pagamentos do negócio.",
  enableGatewayFee: "Ativar taxa do gateway do cliente?",
  areYouSureYOuWantEnableGatewayFee:
    "Tem certeza de que deseja ativar a taxa do gateway do cliente?",
  disableGatewayFee: "Desativar taxa do gateway do cliente?",
  areYouSureYOuWantDisableGatewayFee:
    "Tem certeza de que deseja desativar a taxa do gateway do cliente? A taxa será subtraída dos seus pagamentos!",
  communityTooltip:
    "Certifique-se de ter adicionado o Bot Waylight ao seu grupo/canal como administrador, conectado sua conta do Telegram ao Waylight e que esta é a única oferta para este canal!",
  communityCoverTooltip:
    "Carregue uma imagem ou vídeo para ser exibido na descrição da comunidade.",
  setCard: "Definir",
  expirationDateMustBeInFuture: "A data de expiração deve estar no futuro",
  accessGranted: "Acesso concedido",
  noAccess: "Sem acesso",
  manageCommunity: "Gerenciar comunidade",
  manage: "Gerenciar",
  January: "Janeiro",
  February: "Fevereiro",
  March: "Março",
  April: "Abril",
  May: "Maio",
  June: "Junho",
  July: "Julho",
  August: "Agosto",
  September: "Setembro",
  October: "Outubro",
  November: "Novembro",
  December: "Dezembro",
  toastBuyingYourOwnCommunity:
    "Você não pode se inscrever na sua própria comunidade!",
  priceLimitCommunity: "O preço pode ser de ${{minPrice}} a ${{maxPrice}}.",
  priceLimitCommunityForUA:
    "O preço pode ser de ₴{{minPrice}} a ₴{{maxPrice}}.",
  toastRetryPaymentSuccess: "A sua tentativa de pagamento foi bem-sucedida.",
  toastCommunityLinkCopied: "Link da comunidade copiado",
  communityPaymentFailed: "Pagamento da comunidade não efetuado",
  AddExpirationDateManually: "Adicionar data de expiração manualmente:",
  SetTimeFormat: "Definir formato de hora",
  events: "Eventos",
  newEventOffer: "Nova oferta de evento",
  newEventOfferDescription: "Crie um evento agendado",
  eventName: "Nome do evento",
  eventNameDescription:
    "Escolha um nome para o seu evento, como “Masterclass de SEO”",
  eventCoverTooltip: "Dica da capa do evento",
  getEventPaid:
    "Você recebe pagamento por cada compra de assentos neste evento",
  aboutTheEventOffer: "Sobre o evento",
  dateAndTime: "Data e hora",
  clickToSetDateAndTime: "Clique para definir uma data e hora",
  DescribeYourEvent: "Descreva brevemente o seu evento.",
  onlineEvent: "Evento online",
  offlineEvent: "Evento presencial",
  eventLinkDescription:
    "Adicione informações sobre o local do evento, como Zoom ou Meet",
  uploadOptional: "Enviar (opcional)",
  uploadEventDetails:
    "Carregue aqui qualquer arquivo com detalhes opcionais sobre o evento",
  addInformationAboutLocation:
    "Adicione informações sobre a localização do evento ou um link para o mapa",
  location: "Link de localização",
  address: "Endereço",
  addressOptional: "Endereço (opcional)",
  addAddressEvent: "Adicione o endereço do evento",
  AllDayEvent: "Este é um evento que dura o dia todo.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Evento",
  timeToEvent: "Tempo até o evento",
  hoursEvent: "horas",
  seatsLeft: "lugares disponíveis",
  follower: "Seguidor",
  priceLimitEventForUA: "O preço pode ser de ₴{{minPrice}} a ₴{{maxPrice}}.",
  soldOut: "Esgotado!",
  joinEvents: "Participar de eventos",
  noEventFound: "Nenhum evento encontrado",
  noEventsFoundDescription:
    "Eles aparecerão aqui quando você participar de pelo menos um evento",
  editEvents: "Editar eventos",
  attendForfree: "Participar gratuitamente",
  disabled: "Desativado",
  enabled: "Ativado",
  personalCreditcard: "Pessoal (Cartão de Crédito)",
  privateEntrepreneur: "Empreendedor Individual (IBAN)",
  selectPayoutMethod: "Selecionar método de pagamento",
  enterYourPersonalTaxCode: "Digite o seu código de imposto pessoal*",
  createFondyMerchantAccount:
    "Crie uma conta de comerciante Fondy e complete o processo de integração:",
  create: "Criar",
  participantsMin: "O número mínimo de participantes é 1",
  personalTaxCodeError: "O código fiscal individual deve ser um número válido!",
  participantsError: "A quantidade de participantes deve ser um número válido!",
  peName: "Nome da PE",
  url: "Site da web",
  okpo: "OKPO",
  phone: "Telefone",
  peNameTooltip:
    "O nome oficial da sua empresa individual: 'Sobrenome Nome Patronímico'.",
  urlTooltip:
    "A URL do seu negócio. Isso pode ser o seu site, Facebook, Instagram etc.",
  okpoTooltip: "Código OKPO da sua empresa individual.",
  phonePayoutTooltip: "O seu número de telefone.",
  paymentsTipStripe:
    "Os pagamentos estão sempre habilitados para os usuários do Stripe. Os pagamentos estão disponíveis após a conclusão do registro.",
  paymentsTipFondy1:
    "Empreendedores individuais: os pagamentos são habilitados após a criação do comerciante. Os pagamentos estão disponíveis após a conclusão do registro.",
  paymentsTipFondy2:
    "Cartões de crédito: os pagamentos e transferências estão habilitados após a adição de uma combinação de cartão de crédito pessoal e código fiscal individual.",
  phoneError: "O telefone deve ser um número válido!",
  toastTaxCodeAdded:
    "Seu código de imposto individual foi adicionado com sucesso.",
  toastTaxCodeError:
    "Ocorreu um erro ao tentar adicionar seu código de imposto individual!",
  toastPECodeAdded:
    "Seu novo comerciante Fondy foi criado com sucesso. Verifique seu email para começar a integração!",
  toastPECodeError:
    "Ocorreu um erro ao tentar criar seu novo comerciante Fondy!",
  onboarding: "Onboarding",
  notCompletedOnboarding: "Não concluído",
  completedOnboarding: "Concluído",
  completeOnboarding: "Concluir Onboarding",
  onboardingTooltip:
    "Complete o processo de onboarding no painel de controle da Fondy. Verifique seu email para um convite para criar uma senha, faça login no painel de controle da Fondy, faça upload dos documentos necessários e adicione uma assinatura digital. Após aprovação, sua conta será ativada.",
  fondyMerchantAccountDetails: "Detalhes da conta de comerciante Fondy",
  eventIsLife: "Evento está ao vivo",
  created: "Iniciado",
  processing: "Processando",
  goToFondyDashboard: "Abra o seu painel Fondy",
  youMustCompleteFondy:
    "Você deve concluir o onboarding da Fondy por meio do convite por email que recebeu em:",
  chooseEventType: "Escolha o tipo de evento",
  addException: "Adicionar exceção",
  manageScheduleExceptions: "Gerenciar exceções na agenda",
  addRemoveSpecificTime:
    "Adicione ou remova um horário específico em que você está disponível ou não disponível.",
  exceptionType: "Tipo de exceção:",
  available: "Disponível",
  notAvailable: "Não disponível",
  yes: "Sim",
  no: "Não",
  removeException: "Remover exceção",
  removeExceptionDesc: "Tem certeza de que deseja remover a exceção {{time}}?",
  NoExceptionsAdded: "Nenhuma exceção foi adicionada",
  availableAllDay: "Estarei disponível o dia inteiro.",
  notAvailableAllDay: "Não estarei disponível o dia inteiro.",
  exceptionDeleteSuccessfully: "Exceção removida com sucesso",
  exceptionDeleteError: "Sua exceção não pôde ser removida",
  exceptionSavedSuccessfully: "Exceção salva com sucesso",
  exceptionSavedError: "Sua exceção não pôde ser salva",
  addJoininstructions: "Adicionar instruções de adesão",
  addJoininstructionsDesc:
    "Adicione conteúdo ou mensagem que seus clientes verão após a compra, quando ainda não se uniram ao canal",
  joinChannel: "Entrar no canal",
  communityUploadDescription: "Carregar imagem ou vídeo.",
  detailsOptional: "Detalhes (opcional)",
  type: "Tipo",
  youCannotAddPast:
    "Você não pode adicionar um intervalo de tempo que termina no passado",
  Sun: "Dom",
  Mon: "Seg",
  Tue: "Ter",
  Wed: "Qua",
  Thu: "Qui",
  Fri: "Sex",
  Sat: "Sáb",
  toastEventNotEdited: "Ocorreu um erro ao tentar editar o seu evento!",
  refunded: "reembolsado",
  declined: "recusado",
  verified: "verificado",
  transactionSuccessful: "Transação bem-sucedida",
  myTabs: "Minhas abas",
  tabStatus: "{{tabName}} estão {{status}}",
  controlOfferTabs:
    "Tipos de ofertas que os clientes podem ver na sua página principal:",
  onlyEnglishLetters: "Apenas letras e números em inglês são permitidos",
  notificationCompleteOnboarding: "Complete a integração",
  notificationYourPaidOffersAreUnavailable:
    "Suas ofertas pagas não estão disponíveis para seus clientes.",
  products: "Produtos",
  bookingAvailable: "Disponibilidade para reservas",
  bookingAvailableDescription:
    "Especifique se está disponível para reservas em geral.",
  availableKey: "Disponível",
  notAvailableKey: "Não disponível",
  paymentOccurs: "Pagamento devido a cada",
  paymentOccursOneTime: "Pagamento único.",
  eventIsOver: "O evento acabou",
  integrations: "Integrações",
  externalAnalytics: "Análise externa",
  externalAnalyticsDescription:
    "Defina as suas IDs de contas de análise externa abaixo.",
  participantsManage: "Participantes",
  manageEvent: "Gerir evento",
  noEventsAdded: "Nenhum evento adicionado",
  createEvent: "Criar evento",
  shareEvent: "Compartilhar evento",
  waylightProfile: "Perfil Waylight",
  tickedId: "ID do bilhete",
  checkIn: "Check-in",
  checkedIn: "Marcado como presente",
  notCheckedin: "Não marcado como presente",
  codeIsValid: "Código válido! Marcado como presente!",
  codeisInvalid: "Código inválido! Por favor, tente novamente.",
  codeIsUsed: "Este código já está em uso!",
  buyMore: "Comprar mais",
  noParticipantsYet: "Ainda não há participantes",
  emptyDeteilsBuysTickets:
    "Eles aparecerão aqui quando alguém comprar ingressos para o seu evento.",
  connectYourTelegram: "Conecte sua conta do Telegram para acessar o canal!",
  change: "Mudança",
  numberOfTickets: "Número de bilhetes:",
  selectPaymentMethodTitle: "Selecionar método de pagamento:",
  or: "- ou -",
  addACard: "Adicionar um cartão",
  subscriptionLevels: "Níveis de assinatura",
  subscriptionLevelsTooltip:
    "Adicione até 3 níveis à assinatura da sua comunidade. Por exemplo: mensal, trimestral e anual.",
  levelName: "Nome do nível",
  starter: "Iniciante",
  pro: "Pro",
  levelDiscription: "Nome do nível exibido para os clientes",
  period: "Período",
  periodDiscription:
    "Os clientes serão cobrados para permanecerem inscritos neste intervalo",
  priceDiscription: "Você recebe pagamento em intervalos especificados",
  removeLevel: "Remover nível",
  addAnotherLevel: "Adicionar outro nível",
  noTrial: "Sem período de teste",
  trialDiscription:
    "Quantidade de dias de acesso gratuito que seus clientes receberão",
  trialPeriod: "Período de teste:",
  selectSubscription: "Selecionar assinatura:",
  subscription: "Assinatura",
  removeLevelDescription: "Tem certeza de que deseja remover o nível?",
  tryForFree: "Experimente gratuitamente",
  startTrial: "Iniciar teste",
  subscribedSuccessfully: "Inscrição realizada com sucesso",
  edit: "Editar",
  expirationColumName: "vencimento",
  kick: "Expulsar",
  trial: "Teste",
  notJoined: "Não participou",
  telegramProfile: "Perfil no Telegram",
  notAuthorized: "Não autorizado",
  noMembersFoundFilter:
    "Nenhum membro encontrado de acordo com o filtro definido.",
  tryADifferentFilter:
    "Experimente um filtro diferente ou entrada de pesquisa.",
  "not joined": "Não participou",
  "not authorized": "Não autorizado",
  membership: "Associação",
  priceDiscriptionEvents:
    "Você recebe pagamento por cada compra de assentos neste evento",
  fanZone: "Zona de fãs",
  vipZone: "Zona VIP",
  selectTickets: "Selecionar ingressos:",
  attendance: "Comparecimento",
  eventLevels: "Níveis do evento",
  noTicketsSelected: "Nenhum ingresso selecionado",
  SetMaximumNumberOfSeats:
    "Definir o número máximo de assentos para este nível",
  participants: "Número de assentos",
  import: "Importar",
  export: "Exportar",
  labelNameDescription: "Escolha um rótulo para seus dados importados",
  hidden: "oculto",
  upload: "Enviar",
  labelName: "Nome do rótulo",
  fileIsRequired: "Você deve enviar um arquivo!",
  code: "código",
  level: "Nível",
  label: "Rótulo",
  exportFileError: "Falha na exportação!",
  exportFileSuccess: "Arquivo exportado com sucesso.",
  importFileSuccess: "Arquivo importado com sucesso.",
  importFileError: "Falha na importação!",
  statsSuccess: "Estatísticas de check-in carregadas com sucesso.",
  statsError: "Houve um erro ao carregar as estatísticas de check-in!",
  uploadAText: "Texto simples ou arquivo csv de até 50MB",
  onlyTextFilesAllowed:
    "Apenas arquivos de texto simples ou csv são permitidos!",
  toastCommunityNotEdited: "Houve um erro ao editar a comunidade",
  toastYouCanSelectOnlyImage:
    "Você pode selecionar apenas uma imagem para o cabeçalho",
  sections: "Seções",
  logInOrSignup: "Faça login ou cadastre-se no Waylight abaixo",
  continue: "Continuar",
  invalidEmail: "Email inválido",
  enterCode: "Digite o código",
  haveSentCodeTo: "Enviamos um código para",
  paste: "Colar",
  resendCodeIn: "Reenviar código... (em {{time}} segundos)",
  resendCode: "Reenviar código",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Por favor, escolha o país do seu banco ou serviço financeiro.",
  buyAccessFor: "Comprar acesso por",
  thankYouForFollowing: "Obrigado por seguir!",
  incorrectCode: "O código está incorreto ou expirou!",
  enterEmailToContinue: "Digite o email para continuar",
  city: "Cidade",
  social: "Social",
  earned: "total",
  priceLimitARS:
    "O preço pode ser ARS$ 0, ou de ARS$ {{minPrice}} a ARS$ {{maxPrice}}.",
  priceLimitAUD:
    "O preço pode ser A$ 0, ou de A$ {{minPrice}} a A$ {{maxPrice}}.",
  priceLimitEUR: "O preço pode ser €0, ou de €{{minPrice}} a €{{maxPrice}}.",
  priceLimitBOB:
    "O preço pode ser Bs. 0, ou de Bs. {{minPrice}} a Bs. {{maxPrice}}.",
  priceLimitBGN:
    "O preço pode ser лв 0, ou de лв {{minPrice}} a лв {{maxPrice}}.",
  priceLimitCAD:
    "O preço pode ser CA$ 0, ou de CA$ {{minPrice}} a CA$ {{maxPrice}}.",
  priceLimitCLP:
    "O preço pode ser CL$ 0, ou de CL$ {{minPrice}} a CL$ {{maxPrice}}.",
  priceLimitCOP:
    "O preço pode ser COL$ 0, ou de COL$ {{minPrice}} a COL$ {{maxPrice}}.",
  priceLimitCRC: "O preço pode ser ₡0, ou de ₡{{minPrice}} a ₡{{maxPrice}}.",
  priceLimitHRK:
    "O preço pode ser kn 0, ou de kn {{minPrice}} a kn {{maxPrice}}.",
  priceLimitCZK:
    "O preço pode ser Kč 0, ou de Kč {{minPrice}} a Kč {{maxPrice}}.",
  priceLimitDKK:
    "O preço pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitDOP:
    "O preço pode ser RD$ 0, ou de RD$ {{minPrice}} a RD$ {{maxPrice}}.",
  priceLimitHKD:
    "O preço pode ser HK$ 0, ou de HK$ {{minPrice}} a HK$ {{maxPrice}}.",
  priceLimitEGP:
    "O preço pode ser EGP 0, ou de EGP {{minPrice}} a EGP {{maxPrice}}.",
  priceLimitHUF:
    "O preço pode ser Ft 0, ou de Ft {{minPrice}} a Ft {{maxPrice}}.",
  priceLimitISK:
    "O preço pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitINR: "O preço pode ser ₹0, ou de ₹{{minPrice}} a ₹{{maxPrice}}.",
  priceLimitIDR:
    "O preço pode ser Rp 0, ou de Rp {{minPrice}} a Rp {{maxPrice}}.",
  priceLimitILS: "O preço pode ser ₪0, ou de ₪{{minPrice}} a ₪{{maxPrice}}.",
  priceLimitUYU:
    "O preço pode ser $U 0, ou de $U {{minPrice}} a $U {{maxPrice}}.",
  priceLimitGBP: "O preço pode ser £0, ou de £{{minPrice}} a £{{maxPrice}}.",
  priceLimitTRY: "O preço pode ser ₺0, ou de ₺{{minPrice}} a ₺{{maxPrice}}.",
  priceLimitTTD:
    "O preço pode ser TT$ 0, ou de TT$ {{minPrice}} a TT$ {{maxPrice}}.",
  priceLimitTHB: "O preço pode ser ฿0, ou de ฿{{minPrice}} a ฿{{maxPrice}}.",
  priceLimitCHF:
    "O preço pode ser CHF 0, ou de CHF {{minPrice}} a CHF {{maxPrice}}.",
  priceLimitSEK:
    "O preço pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitZAR: "O preço pode ser R 0, ou de R {{minPrice}} a R {{maxPrice}}.",
  priceLimitSGD:
    "O preço pode ser SGD$ 0, ou de SGD$ {{minPrice}} a SGD$ {{maxPrice}}.",
  priceLimitSAR:
    "O preço pode ser SAR 0, ou de SAR {{minPrice}} a SAR {{maxPrice}}.",
  priceLimitRON:
    "O preço pode ser lei 0, ou de lei {{minPrice}} a lei {{maxPrice}}.",
  priceLimitPLN:
    "O preço pode ser zł 0, ou de zł {{minPrice}} a zł {{maxPrice}}.",
  priceLimitPHP: "O preço pode ser ₱ 0, ou de ₱ {{minPrice}} a ₱ {{maxPrice}}.",
  priceLimitPEN:
    "O preço pode ser S/. 0, ou de S/. {{minPrice}} a S/. {{maxPrice}}.",
  priceLimitPYG: "O preço pode ser ₲ 0, ou de ₲ {{minPrice}} a ₲ {{maxPrice}}.",
  priceLimitNOK:
    "O preço pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitNZD:
    "O preço pode ser NZ$ 0, ou de NZ$ {{minPrice}} a NZ$ {{maxPrice}}.",
  priceLimitMXN:
    "O preço pode ser MX$ 0, ou de MX$ {{minPrice}} a MX$ {{maxPrice}}.",
  priceLimitKES:
    "O preço pode ser KSh 0, ou de KSh {{minPrice}} a KSh {{maxPrice}}.",
  linkInBIOtool: "Ferramenta Link na BIO",
  thatHelpsYouEarn: "que ajuda você a ganhar!",
  earnMore: "Ganhe mais",
  withWaylight: "com Waylight:",
  descriptionEarn: "Faça 10X na sua receita de mídia social!",
  descriptionEarnPart: "Lance em apenas 2 minutos!",
  earnOnPaidCommunities: "Ganhe em Comunidades Pagas.",
  createAndManage:
    "Crie e gerencie suas comunidades baseadas em assinatura no Telegram.",
  EarnPaidDigital: "Ganhe em produtos digitais pagos.",
  hostAndManage:
    "Hospede e gerencie seus cursos, tutoriais, e-books, guias, arquivos, etc.",
  earnOnline: "Ganhe em Eventos Online e Presenciais.",
  sellTicketsAndManage:
    "Venda ingressos e gerencie seus eventos em grupo online e presenciais.",
  EarnServices: "Ganhe em Serviços Pagos.",
  sellManage: "Venda e gerencie o fluxo de seus serviços.",
  earnOnlineVideoSessions: "Ganhe em sessões de vídeo online pagas.",

  EarnAppointments: "Ganhe em Consultas Presenciais Pagas.",
  ScheduleSignups:
    "Agende um cronograma e obtenha inscrições para suas consultas individuais ou em grupo.",
  earnTipping: "Ganhe em Gorjetas.",
  getTippingSupport:
    "Receba gorjetas em apoio aos seus canais ou por compartilhar conteúdo em redes sociais.",
  youWilGet: "Você receberá",
  tools: "1. Ferramentas",
  sales: "2. Vendas",
  clientsGet: "3. Clientes",
  howItWorks: "Como funciona",
  GetClients: "Obtenha novas vendas e clientes",
  useWaylightGrowth:
    "Use as ferramentas e conselhos do motor de crescimento Waylight para vender mais e conquistar novos clientes nas redes sociais.",
  createWaylight: "Crie Waylight",
  creatorsExperts: "Criadores e especialistas",
  interactionsMonthly: "Interações mensais",
  monthOverMonth: "Crescimento mês a mês",
  forWhom: "Para quem:",
  pricing: "Preços:",
  chooseYourPlan: "Escolha seu plano",
  saveWhenChoosingYearlyPlan: "Economize mais ao escolher um plano anual!",
  monthlyBilling: "Cobrança mensal",
  annuallyBilling: "Cobrança anual",
  freePlan: "Grátis",
  startLowestCosts: "Comece com os custos mais baixos e explore a Waylight",
  mo: "/mês",
  signUp: "Inscreva-se",
  impressionMo: "{{comission}}% de taxas de transação.",
  basicToolsIncluded: "Ferramentas básicas estão incluídas!",
  basic: "Básico",
  getEverythingBasic:
    "Receba tudo no grátis com um nível menor de taxas de transação",
  contactSales: "Contate as vendas",
  transactionFees: "Taxas de transação de 2%.",
  publishingMarketplace: "Publicação na busca do mercado.",
  saveBadge: "Economize {{percent}}%",
  bestValue: "Melhor valor",
  GetMoreSales:
    "Obtenha mais vendas de suas redes sociais sem taxas de transação",
  ziroTransactionFees: "0% de taxas de transação.",
  proToolsIncluded: "Ferramentas profissionais estão incluídas!",
  customizations: "Customizações.",
  verifiedSign: "Sinal verificado.",
  prioritySupport: "Suporte prioritário.",
  launchExpert: "Especialista de lançamento está incluído.",
  askTelegram: "Pergunte no Telegram",
  askWhatsApp: "Pergunte no WhatsApp",
  askEmail: "Pergunte por e-mail",
  MakeTenX: "Faça 10X",
  onYourSocialMedia: "nas suas redes sociais",
  revenue: "receita! 🎉",
  launchJust: "Lance em apenas",
  twoMinutes: "2 minutos!",
  product: "Produto:",
  customersDataManagement: "Gerenciamento de dados de clientes",
  reviewsProduct: "Avaliações",
  portfolioProduct: "Portfólio",
  businessWebsite: "Site de negócios",
  securePayments: "Pagamentos seguros",
  tipping: "Gorjetas",
  paidAppointments: "Consultas pagas presenciais",
  paidServices: "Serviços pagos",
  ticketsOnlineEvents: "Ingressos para eventos online",
  ticketsScanner: "Scanner de ingressos",
  ticketsPersonEvents: "Ingressos para eventos presenciais",
  paidAccessCourses: "Acesso pago a cursos",
  paidAccessDigitalProducts: "Acesso pago a produtos digitais",
  paidGroupChannelAccessTelegram: "Acesso pago a grupos e canais no Telegram",
  paidSubscriptionCommunitiesTelegram:
    "Comunidades pagas baseadas em assinatura no Telegram",
  productMenuItem: "Produto",
  PricingMenuItem: "Preços",
  Creator: "Criador",
  Freelancer: "Freelancer",
  Tutor: "Tutor",
  Coach: "Treinador",
  Influencer: "Influenciador",
  ask: "Pergunte",
  anyQuestions: "qualquer pergunta aqui:",
  how: "Como",
  itWorks: "funciona",
  businessflowStep1new:
    "Adicione seu link Waylight à BIO das redes sociais e comece a envolver seu público e clientes compartilhando histórias e postagens sobre isso.",
  businessflowStep2new:
    "Use as ferramentas e conselhos do motor de crescimento da Waylight para vender mais e obter novos clientes das redes sociais.",

  businessCalculatorNew: "Calculadora de renda Waylight",
  howMuchMoneyNew:
    "Descubra quanto dinheiro os assinantes trariam para o seu negócio.",
  estimateNumberNew: "1. Estime o número de clientes e assinantes",
  howMuchYouChargeNew: "2. Quanto você pode cobrar por uma oferta",
  for: "Para",
  whom: "para quem",
  getEverythingBasicNew:
    "Obtenha tudo no básico com um nível de taxas de transação mais baixo",
  startLowestCostsNew: "Comece sem custos iniciais e explore a Waylight",
  GetMoreSalesNew:
    "Obtenha mais vendas nas suas redes sociais sem taxas de transação",
  onYourSocialMediaNew: "no seu faturamento nas redes sociais! 🎉",
  accordionv2label0: "Em quais países vocês trabalham?",
  accordionv2text0:
    "A Waylight Business opera em mais de 55 países em 5 continentes. Você pode entrar em contato com nosso suporte sobre seu país ou simplesmente tentar se inscrever e conferir. Os clientes podem pagar por suas ofertas de mais de 150 países ao redor do mundo.",
  accordionv2label1: "Como eu recebo pagamento?",
  accordionv2text1:
    "Você receberá pagamentos em sua conta bancária toda segunda-feira. Leva menos de 1 minuto para conectar sua conta bancária à Waylight Business.",
  accordionv2label2: "Quais ferramentas estão incluídas no preço?",

  accordionv2label3: "Vocês têm Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Sim, temos. Chamamos isso de 'Smooth Checkout'. A Waylight está trabalhando para automatizar os fluxos de vendas com taxas de conversão mais altas, então temos checkouts de 1 clique (com o PayPal a caminho).",
  accordionv2label4: "Como a Waylight me ajudará a fazer 10 vezes mais?",
  accordionv2text4:
    "Você terá acesso ao motor de crescimento de vendas e à academia de monetização, que ajudarão você a vender mais e obter mais clientes das suas redes sociais. Além disso, a Waylight já criou fluxos e ferramentas automatizados que proporcionam taxas de conversão mais altas.",
  accordionv2label5: "O que eu preciso para começar?",
  accordionv2text5:
    "É fácil 👌 Inscreva-se, adicione sua conta bancária, crie ofertas, junte-se à academia de monetização, adicione links nas redes sociais e comece a ganhar mais e mais dinheiro.",
  connectWaylight: "Conecte-se no Waylight!",
  toastTooLargeFileTwilio:
    "Este arquivo é muito grande. O tamanho máximo do arquivo é de 150 MB.",
  enableSold: "Ativar indicadores de vendas de ofertas?",
  areYouSureYOuWantEnableSold:
    "Você tem certeza de que deseja ativar os indicadores de vendas de ofertas? Os valores de venda serão visíveis para todos os usuários.",
  disableSold: "Desativar indicadores de vendas de ofertas?",
  areYouSureYOuWantDisableSold:
    "Você tem certeza de que deseja desativar os indicadores de vendas de ofertas?",
  soldEnabled: "Os indicadores de vendas de ofertas estão ativados",
  soldEnabledDescription:
    "Quando esta configuração está ativada, os valores de venda são visíveis para todos os usuários.",
  tipOrCommunitypriceLimitARS:
    "O preço pode variar de ARS$ 1879.31 a ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "O preço pode variar de A$ 3.01 a A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "O preço pode variar de €1.81 a €9070.",
  tipOrCommunitypriceLimitBOB:
    "O preço pode variar de Bs. 13.81 a Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "O preço pode variar de лв 3.55 a лв 17750.81.",
  tipOrCommunitypriceLimitCAD:
    "O preço pode variar de CA$ 2.74 a CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "O preço pode variar de CL$ 1864.56 a CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "O preço pode variar de COL$ 8072.11 a COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "O preço pode variar de ₡1055.02 a ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "O preço pode variar de kn 36 a kn 70472.",
  tipOrCommunitypriceLimitCZK: "O preço pode variar de Kč 45.71 a Kč 228558.",
  tipOrCommunitypriceLimitDKK: "O preço pode variar de kr 13.54 a kr 67719.",
  tipOrCommunitypriceLimitDOP: "O preço pode variar de RD$ 295 a RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "O preço pode variar de HK$ 15.58 a HK$ 77915.",
  tipOrCommunitypriceLimitEGP:
    "O preço pode variar de EGP 154.75 a EGP 309501.",
  tipOrCommunitypriceLimitHUF: "O preço pode variar de Ft 716 a Ft 3579217.",
  tipOrCommunitypriceLimitISK: "O preço pode variar de kr 684 a kr 1369300.",
  tipOrCommunitypriceLimitINR: "O preço pode variar de ₹167.89 a ₹839435.",
  tipOrCommunitypriceLimitIDR:
    "O preço pode variar de Rp 78852 a Rp 157703833.",
  tipOrCommunitypriceLimitILS: "O preço pode variar de ₪19 a ₪36657.",
  tipOrCommunitypriceLimitUYU: "O preço pode variar de $U 81 a $U 402925.",
  tipOrCommunitypriceLimitGBP: "O preço pode variar de £1.56 a £7779.95.",
  tipOrCommunitypriceLimitTRY: "O preço pode variar de ₺67.06 a ₺335289.19.",
  tipOrCommunitypriceLimitTTD:
    "O preço pode variar de TT$ 13.58 a TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "O preço pode variar de ฿69.82 a ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "O preço pode variar de CHF 1.73 a CHF 8647.",
  tipOrCommunitypriceLimitSEK:
    "O preço pode variar de kr 20.82 a kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "O preço pode variar de R 94 a R 186359.",
  tipOrCommunitypriceLimitSGD:
    "O preço pode variar de SGD$ 2.63 a SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "O preço pode variar de SAR 19 a SAR 37496.",
  tipOrCommunitypriceLimitRON:
    "O preço pode variar de lei 9.04 a lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "O preço pode variar de zł 7.8 a zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "O preço pode variar de ₱ 281 a ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "O preço pode variar de S/. 19 a S/. 38040.",
  tipOrCommunitypriceLimitPYG: "O preço pode variar de ₲ 36387 a ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "O preço pode variar de kr 21.38 a kr 106903.",
  tipOrCommunitypriceLimitNZD: "O preço pode variar de NZ$ 3.33 a NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "O preço pode variar de MX$ 37.92 a MX$ 189595.",
  tipOrCommunitypriceLimitKES: "O preço pode variar de KSh 803 a KSh 1605000.",

  authorizeToAccess: "Você precisa autorizar para acessar",
  noCommunitiesFound: "Nenhuma comunidade encontrada",
  noCommunitiesFoundDescription:
    "Elas aparecerão aqui quando você comprar ou adicionar uma comunidade",
  following: "Seguindo",
  appointments: "Compromissos",
  share: "Compartilhar",
  addOffer: "Adicionar oferta",
  aboutMe: "Sobre mim",
  estimatedAmount:
    "Montante total de transferências bem-sucedidas feitas para a sua conta Stripe incorporada na sua moeda padrão.",
  ConnectYourTelegram: "Conecte seu Telegram:",
  ConnectYourTelegramAccount:
    "Conecte sua conta no Telegram para receber notificações dos Criadores que você está seguindo.",
  connectTelegram: "Conectar Telegram",
  buyAgain: "Comprar novamente",
  purchases: "Comprado",
  videoGuide: "Guia em vídeo",
  businessBilling: "Faturamento Empresarial",
  noPaymentMethodsAdded: "Nenhum método de pagamento adicionado",
  yourPaymentMethods: "Seus métodos de pagamento",
  yourPlan: "Seu plano",
  yearlyBilling: "Faturamento anual",
  selectFree: "Selecionar grátis",
  whatIncluded: "O que está incluído",
  selectStarter: "Selecionar Básico",
  selectPro: "Selecionar Pro",
  enterPromoCode: "Digite o código promocional:",
  apply: "Aplicar",
  selected: "Selecionado",
  waylightSubscription: "Assinatura Waylight",
  cashbackRefferals: "Indicações",
  referrals: "Afiliado",
  shareLink: "Compartilhar link",
  howReferralsWork: "Como funcionam as indicações",
  cashback: "Cashback",
  yourCashbackCode: "Seu código de cashback",
  yourCustomPartnerReferralCode:
    "Seu código de indicação de parceiro personalizado",
  howCashbackWork: "Como funciona o cashback",
  toastPromoCodeSuccess: "Seu código promocional foi aplicado com sucesso!",
  toastPromoCodeError: "Houve um erro ao aplicar seu código promocional!",
  enterPromoCodeSignUp: "Digite o código promocional (opcional):",
  enterPromoCodeBtn: "+ Digite o código promocional",
  billingBusinessTooltip: "Selecione seu plano abaixo.",
  referralTooltip: "Este é o seu código de cashback exclusivo.",
  cashbackTooltip: "Este é o seu código de afiliado exclusivo.",
  currentPlan: "Plano atual:",
  neverExpires: "Nunca",
  expires: "Expira em:",
  emptyBillingBusinessHistoryDescription:
    "Descrição do histórico de faturamento empresarial vazio",
  notificationGoToBillingBusiness:
    "Encontramos um problema com o pagamento da sua assinatura! ",
  WeHaveEncounteredAnIssueSubscription:
    "Encontramos um problema com o pagamento da sua assinatura",
  checkIfYouHaveSufficientSubscription:
    "Verifique se você tem fundos suficientes em seu saldo ou se os pagamentos pela internet estão habilitados.",
  selectDifferentPaymentMethodSubscription:
    "Selecione um método de pagamento diferente.",
  referralsDescriptions1: "Obrigado por ser nosso valioso parceiro afiliado!",
  referralsDescriptions2:
    "Complete o onboarding para receber seus pagamentos de afiliados. Mantemos seu dinheiro seguro até você estar a bordo.",
  referralsDescriptions3:
    "Você precisará dos dados de seu banco ou cartão, dependendo do seu país.",
  referralsDescriptions4:
    "Os pagamentos ocorrem toda segunda-feira. Você pode verificar o status do pagamento em Transações.",
  referralsDescriptions5:
    "Seu código de afiliado fornece descontos adicionais para assinaturas Waylight.",
  referralsDescriptions6:
    "Suas taxas de desconto e compartilhamento de receita são personalizadas com base em nosso acordo.",
  referralsDescriptions7:
    "Se esta for a primeira vez que você está recebendo o pagamento, pode levar de 7 a 14 dias úteis para chegar devido às limitações do gateway. Normalmente, os pagamentos levam cerca de 3 dias úteis para chegar.",
  cashbackDescriptions1:
    "Obrigado por ser membro do nosso programa de cashback!",
  cashbackDescriptions2:
    "Complete o onboarding para receber seus pagamentos de afiliados. Mantemos seu dinheiro seguro até você estar a bordo.",
  cashbackDescriptions3:
    "Você precisará dos dados de seu banco ou cartão, dependendo do seu país.",
  cashbackDescriptions4:
    "Os pagamentos ocorrem toda segunda-feira. Você pode verificar o status do pagamento em Transações.",
  cashbackDescriptions5:
    "Compartilhe seu link e obtenha compartilhamento de receita de novos clientes.",
  cashbackDescriptions6:
    "Seu cashback é de 20 a 30% de todas as taxas da Waylight por 1 ano (apenas 30% das assinaturas anuais Pro).",
  cashbackDescriptions7:
    "Se esta for a primeira vez que você está recebendo o pagamento, pode levar de 7 a 14 dias úteis para chegar devido às limitações do gateway. Normalmente, os pagamentos levam cerca de 3 dias úteis para chegar.",
  invalidGTMiD:
    "ID GTM inválido. Insira apenas seu ID, não o código, por exemplo: GTM-AA11BB2.",
  invalidMetaPixelID:
    "ID Meta Pixel inválido. Insira apenas seu ID, não o código, por exemplo: 123456789012345.",
  invalidHotjarID:
    "ID Hotjar inválido. Insira apenas seu ID, não o código, por exemplo: 1112223.",
  accountCreated: "Conta criada",
  toastOUrlCopied: "Link de indicação copiado com sucesso!",
  YouAreOnFreePlan: "Você já está atualmente no plano gratuito",
  referralCodeCanNotBeUsed:
    "Este código de indicação não pode ser usado para o país de sua conta atual: {{country}}",
  createWaylightBusiness: "Criar Negócio Waylight",
  pleaseChooseCountryBank:
    "Por favor, escolha o país do seu banco ou serviço financeiro:",
  referralsDescriptionsUA4:
    "Os pagamentos ocorrem após cada transação. Você pode verificar o status do pagamento em Transações.",
  referralsDescriptionsUA7: "Os pagamentos ocorrem até o próximo dia útil.",
  cashbackDescriptionsUA4:
    "Os pagamentos ocorrem após cada transação. Você pode verificar o status do pagamento em Transações.",
  cashbackDescriptionsUA7: "Os pagamentos ocorrem até o próximo dia útil.",
  issueSubscriptionPayment:
    "Encontramos um problema com o pagamento da sua assinatura!",
  revenueSharhingSelectPE:
    "O programa de indicação está disponível apenas para contas PE ativadas na Ucrânia.",
  youCanChangeYourAccount:
    "Você pode alterar o tipo da sua conta nas configurações.",
  yourReferralCodeWillAppear:
    "Seu código de indicação aparecerá aqui assim que você concluir o onboarding de PE.",
  goToPayoutSettings: "Ir para configurações de pagamento",
  commission: "Comissão",
  youAreOnSamePlan:
    "Você já está no mesmo plano que está tentando escolher! Por favor, escolha outro plano.",
  onlyCyrillicLettersAllowed: "Apenas letras cirílicas são permitidas",
  dragToChangePosition: "Arraste para mudar a posição",
  eventsQrcode: "Códigos curtos de evento",
  eventsQrcodeDescription:
    "Quando esta configuração está ativada, seus ingressos para eventos serão gerados com PINs curtos de 4 dígitos e códigos QR. Quando desativada, serão gerados PINs mais seguros de 8 dígitos com códigos de barras.",
  areYouSureYOuWantEnableQrcode:
    "Tem certeza de que deseja habilitar os PINs curtos de evento com códigos QR?",
  areYouSureYOuWantDisableQrcode:
    "Tem certeza de que deseja desabilitar os PINs curtos de evento e gerar códigos de barras em vez disso?",
  enableEventsQrcode: "Ativar códigos curtos de evento",
  disableEventsQrcode:
    "Desativar códigos curtos e alternar para códigos de barras",
  authorizeScanner: "Autorizar scanner",
  toastScannerUrlCopied: "URL do scanner copiado",
  total: "Total:",
  gtmIdDescription: "GTM local para esta oferta.",
  metaPixelIdDescription: "Meta Pixel local para esta oferta.",
  tutorialBoughtPreview:
    "Esta é a pré-visualização do estado comprado do seu produto.",
  tutorialNotBoughtPreview:
    "É assim que os clientes veem o seu produto quando não está comprado.",
  authorizeToAccessPurchases: "Por favor, autorize o acesso às suas compras!",
  myPromotions: "Meu código promocional",
  describeTheDetailsPromotions:
    "Selecione a oferta, especifique o nome, escolha um URL e adicione os detalhes da promoção abaixo",
  promotionDropdownDescription:
    "Selecione sua oferta na lista. Você não poderá alterá-la posteriormente para esta promoção.",
  offerNamePromotions: "Oferta",
  promotionsTooltip:
    "Selecione uma oferta para a qual esta promoção será aplicada. Você pode criar quantas outras promoções desejar para esta oferta.",
  offerNameDescriptionPromotions:
    "Insira o nome da promoção. Isto é apenas para seus propósitos internos.",
  codePromotionsDescription:
    "Insira um código promocional exclusivo usando letras e números em inglês. Experimente o botão mágico de geração, se desejar!",
  expirationPromotions: "Expiração",
  name: "Nome",
  codePromotions: "Código",
  usersNumDescription:
    "Este código promocional pode ser usado uma quantidade especificada de vezes.",
  max_uses: "Número de usos",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Desconto",
  discountTitle: "Desconto(s):",
  selectOfferDiscounts:
    "Selecione a oferta acima para começar a gerenciar os descontos para ela.",
  promoNameCanNotBeEmpty: "O nome da promoção não pode estar vazio",
  urlIsAlreadyTaken: "Este URL já está em uso!",
  promoCodeValidation:
    "O código promocional só pode conter letras maiúsculas e minúsculas em inglês e números de 0 a 9!",
  promotions: "Promoções",
  enterPromoCodePromotions: "Insira o código promocional",
  promoCodeIncorrectExpired:
    "Este código promocional está incorreto ou expirou!",
  selectedPromoCodeNotAvailable:
    "O código promocional selecionado não está disponível!",
  weWereUnableToProcessYourTransaction:
    "Não foi possível processar sua transação com o código promocional selecionado devido à expiração, limite de uso ou indisponibilidade. Por favor, inicie sua compra novamente.",
  promotionsEmptyStateHeader:
    "Nenhuma promoção encontrada de acordo com o filtro definido.",
  promotionsEmptyStateDescription:
    "Crie promoções, tente um filtro diferente ou uma pesquisa.",
  toastPromotionUrlCopied: "URL da promoção copiada!",
  toastPromotionCodeCopied: "Código promocional copiado!",
  managePromotionsForYourOffers: "Gerencie promoções para suas ofertas",
  offerPromotions: "Promoções de ofertas",
  deletePromotionDescription: "Tem certeza de que deseja excluir a promoção?",
  deletePromotionTitle: "Excluir promoção",
  addPromotion: "Adicionar promoção",
  toastPromotionNotEditedEnabled: "Não conseguimos modificar sua promoção!",
  toastPromotionNotEdited: "Não conseguimos modificar sua promoção!",
  usage: "Usado",
  discountedPriceError:
    "O preço com desconto não pode ser inferior ao equivalente de 2 USD!",
  priceWithDiscount: "Preço com desconto",
  promoCodeAddedToast: "Código promocional aplicado com sucesso!",
  toastPromotionSavedSuccessfully: "Código promocional criado com sucesso",
  toastPromotiomNotAdded: "Ocorreu um erro ao criar sua promoção!",
  myPromotionsMenu: "Minhas promoções",
  setSubscriptionlevelManually: "Definir nível de assinatura manualmente:",
  settingLevelManuallyNotifyClientAutomatically:
    "Definir um nível manualmente notificará o cliente automaticamente e será aplicado com a próxima cobrança em {{expiration}}",
  subscribeForFree: "Inscreva-se gratuitamente",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Nível da comunidade salvo e atualizado com sucesso.",
  toastCommunityLevelNotUpdated: "Nível da comunidade não atualizado.",
  addNewCardStripe: "Adicionar novo cartão (Stripe):",
  creditCard: "Cartão de crédito",
  promocode: "Promocode",
  nonFollowers: "Não seguidores",
  dueOn: "Para o",
  exportMyClientsSuccess: "Exportação de clientes concluída com sucesso.",
  exportMyClientsError: "Erro durante a exportação de clientes.",
  eventTelegramDescription:
    "Selecione o nome do seu canal ou grupo. Se você não vir nada aqui, certifique-se de adicionar o Waylight Bot ao seu grupo ou canal como administrador e esta é a única oferta para este canal!",
  onlineTelegramEvent: "Evento de telegrama online",
  joinEvent: "Participar do evento",
  levels: "Níveis",
  paidAccess: "Acesso pago",
  clientWillPayOnceAccess: "O cliente pagará uma vez para obter acesso",
  noOffersFound: "Nenhuma oferta encontrada",
  noOffersFoundDescription:
    "Elas aparecerão aqui quando você comprar ou adicionar uma oferta",
  youCanNotManageEnabled:
    "Você não tem nenhuma oferta habilitada que possa gerenciar.",

  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Você recebe o valor especificado por um tempo fixo.",
  videoCallQuick: "Chamada de vídeo",
  tabs: "Abas",

  disconnectWhatsAppBot: "Desconectar WhatsApp-bot",
  connectWhatsAppBot: "Conectar WhatsApp-bot",
  WhatsAppBot: "WhatsApp-bot",
  videoCallSession: "Chamada de vídeo",
  offerCardSize: "Tamanho do cartão de oferta",
  large: "Grande",
  small: "Pequeno",
  chooseCardSizeOffer: "Escolha o tamanho do cartão para sua oferta na lista",
  copyProfileLink: "Copiar link do perfil",
  profileQRCode: "Meu código QR do perfil",
  copyCommunitiesLink: "Link das comunidades",
  copyEventsLink: "Link dos eventos",
  copyProductsLink: "Link dos produtos",
  copySessionsLink: "Link de compromissos",
  copyTipsLink: "Link das dicas",
  copyPortfolioLink: "Link do portfólio",
  copyReviewsLink: "Link das avaliações",
  leaveReviewForMe: "Deixe uma avaliação para mim",
  shareYourWaylight: "Compartilhe seu Waylight",
  myProfileQRCode: "Meu código QR do perfil",
  menu: "Menu",
  selectOfferExportData: "Selecione uma oferta para exportar dados",
  setCancellationDelay: "Definir atraso de cancelamento",
  approvalRequired: "Aprovação necessária",
  cancellationFailed: "Falha no cancelamento",
  youUnableCancelDesctiption:
    "Você não pode cancelar um compromisso dentro de {{cancelDelay}} horas antes de começar. Entre em contato com o proprietário do compromisso para mais detalhes.",
  rescheduleFailed: "Falha ao reagendar",
  unableRescheduleDescription:
    "Você não pode reagendar um compromisso dentro de {{rescheduleDelay}} horas antes de começar. Entre em contato com o proprietário do compromisso para mais detalhes.",
  errorRefundDescription:
    "Ocorreu um erro ao cancelar o compromisso. Por favor, entre em contato com ",
  refundFailed: "Falha no reembolso",
  appointment: "Compromisso",
  addInformationAboutAppointment:
    "Adicione informações sobre o compromisso que seus clientes desbloquearão.",
  addMapLink: "Adicionar link do mapa",
  addAddressAppointment: "Adicionar endereço do compromisso",
  uploadFileOptionalAppointmentDetails:
    "Carregue qualquer arquivo com detalhes opcionais do compromisso",
  locationOptional: "Link de localização (opcional)",
  appointmentName: "Nome do compromisso",
  appointmentErrorName: "O nome do compromisso não pode estar vazio",
  upcomingIn: "Próximo em ",
  joinVideoCall: "Participar de chamada de vídeo",
  cancelAppointment: "Cancelar compromisso",
  areYouSureWantCancelClient:
    "Tem certeza de que deseja cancelar? Seu pagamento será reembolsado em até 5-10 dias. As taxas da gateway de pagamento podem ser retidas.",
  areYouSureWantCancelExpert:
    "Tem certeza de que deseja cancelar? O pagamento do seu cliente será reembolsado em até 5-10 dias. As taxas da gateway de pagamento podem ser retidas.",
  aboutAppointment: "Sobre a oferta",
  finishAppointmentTitle: "Tem certeza de que deseja finalizar o compromisso?",
  finishAppointmentDescription:
    "O status do seu compromisso será alterado para ‘concluído’ e ele será encerrado.",
  newAppointmentOffer: "Nova oferta de compromisso",
  editAppointments: "Editar compromissos",
  cancellationReason: "Motivo de cancelamento",
  aboutAppointmentCreatUpdate: "Sobre o compromisso",
  totalSessions: "Total de compromissos",
  yourSession: "Seu compromisso",
  notHaveUpcomingSessions: "Você ainda não tem compromissos futuros",
  notHavePastSessions: "Você ainda não tem compromissos passados",
  notHaveQuickSessions: "Você ainda não iniciou compromissos rápidos",
  upcomingSessions: "Próximos compromissos",
  session: "Compromisso",
  sessionWith: "compromisso com",
  requestSession: "Solicitar compromisso",
  goToSession: "Ir para o compromisso",
  sessions: "Compromissos",
  declineSession: "Recusar compromisso",
  wantDeclineSession: "Tem certeza de que deseja recusar este compromisso?",
  endSession: "Terminar compromisso",
  areYouSureYouWantEndSession:
    "Tem certeza de que deseja terminar este compromisso?",
  aboutOffer: "Sobre o compromisso",
  aboutTheOffer: "Sobre o compromisso",
  noTransactionsDescription:
    "Eles aparecerão assim que você receber compromissos pagos com sucesso",
  sessionsPassed: "Compromissos passados",
  upcomingSession: "Próximo compromisso",
  bookSession: "Reservar compromisso",
  sessionsManagement: "Gerenciamento de compromissos",
  hadNoSessions: "Sem compromissos esta semana",
  bookASession: "Quando você reservar um compromisso, ele será exibido aqui",
  knowEachOther: "4. Conheçam-se e planejem seus compromissos",
  describeRequirements:
    "Descreva os requisitos para os clientes antes de participar dos compromissos: mensagem ou anexos.",
  preCharge: "Uma pré-cobrança será feita para solicitar seu compromisso.",
  fewRecomendations:
    "Algumas recomendações de como tornar seu compromisso confortável e produtivo.",
  enjoyTheSession: "Aproveite o compromisso!",
  getToKnow: "4. Conheçam-se e planejem seus compromissos",
  approveSession: "Aprovar compromisso",
  approveThisSession:
    "Tem certeza de que deseja aceitar este compromisso? Ao aprovar, você concorda em participar no horário especificado.",
  toastSessionCanceled:
    "Este compromisso foi cancelado devido a restrições de tempo",
  toastSessionWithYourself:
    "Você não pode reservar um compromisso consigo mesmo!",
  toastNotMayJoin:
    "Infelizmente, você não pode participar deste compromisso, pois ele atingiu sua capacidade.",
  toastCannotStartSession: "Não é possível iniciar o compromisso rápido agora",
  notificationAddPaymentMethod:
    "Adicione um método de pagamento para poder reservar compromissos.",
  notificationSetupVariousKindsOfSessions:
    "Configure vários tipos de compromissos para seus clientes, que estarão disponíveis para reserva no seu perfil.",
  notificationSetupSchedule:
    "Configure um horário e seus clientes só poderão reservar compromissos de acordo com ele.",
  cancelSession: "Cancelar compromisso",
  paymentFailedDescription:
    "Seu pagamento não foi processado e não conseguimos solicitar um novo compromisso.",
  areYouSureCancel: "Tem certeza de que deseja cancelar este compromisso?",
  sessionEnded: "Compromisso encerrado com sucesso",
  wereNoChatMessages: "Não houve mensagens de chat neste compromisso",
  scrollPastHistory: "Role pela história do seu compromisso passado acima",
  earnedFromLastSession: "ganhou no último compromisso",
  emptyBillingHistoryDescription:
    "Eles aparecerão aqui quando você começar a reservar compromissos",
  editOffers: "Editar compromissos",
  freeChatWarning:
    "Esta conta desativou o chat gratuito. Você pode solicitar um compromisso para se comunicar.",
  unableToJoinSession: "Não foi possível participar do compromisso",
  scheduleSession: "Agende um compromisso com um especialista ou criador.",
  startSessionImmediately:
    "Inicie um compromisso gratuito imediatamente. Convide outros diretamente com um link.",
  quickSessionWarningTittle: "Tem certeza de que deseja iniciar o compromisso?",
  quickSessionWarningDescription:
    "Você tem um compromisso agendado que se sobrepõe ao compromisso rápido que você está tentando iniciar.",
  expertSessionDecision:
    "Você gostaria de terminar o compromisso ou esperar mais tempo? Terminar o compromisso resultará na transferência do pagamento para sua conta.",
  clientSessionDecision:
    "Você gostaria de terminar o compromisso ou esperar mais tempo? Terminar o compromisso reembolsará seu pagamento.",
  cancelledSessionTittle: "Este compromisso foi cancelado",
  areYouSureYouWantLeaveSession:
    "Tem certeza de que deseja sair do compromisso?",
  areYouSureYouWantLeaveSessionDescription:
    "Você poderá retornar ao compromisso até que ele termine.",
  addExtraMinutesDescription:
    "Tem certeza de que deseja adicionar mais 10 minutos a este compromisso?",
  unableAddExtraMinutesDescription:
    "Infelizmente, você tem um compromisso agendado em menos de 10 minutos.",
  freeSessionDecision:
    "Você gostaria de terminar o compromisso ou esperar mais tempo?",
  newSessionOffer: "Nova oferta de compromisso",
  newSessionOfferDescription: "Crie um compromisso de vídeo pago ou gratuito",
  sessionName: "Nome do compromisso",
  createSessionOffer: "Criar oferta de compromisso",
  createSessionOfferDescription:
    "Adicione um compromisso de vídeo pago ou gratuito.",
  rescheduleDelayDescription:
    "Especifique com quanto tempo de antecedência seus compromissos podem ser remarcados.",
  rescheduleSession: "Reagendar compromisso",
  rescheduleSessionDescription:
    "Tem certeza de que deseja reagendar o compromisso?",
  cannotRescheduleSession: "Não é possível reagendar o compromisso",
  ScheduleBookingsOnlineVideo:
    "Configure um horário e receba reservas para compromissos de vídeo online com seus clientes.",
  paidVideoSessions: "Compromissos de vídeo online pagos",
  businessflowStep3new:
    "Receba pagamentos por suas comunidades, produtos digitais, eventos online e offline, gorjetas, compromissos presenciais e de vídeo, etc.",
  accordionv2text2:
    "Depende do plano que você escolher, mas em qualquer caso você terá acesso aos fluxos de negócios completos de monetização do Waylight, como comunidades pagas no Telegram, ingressos, produtos digitais, cursos, compromissos, compromissos de vídeo, gorjetas, site de negócios, dados de clientes, motores de vendas, academia de monetização, etc.",
  enableOffersForManagement:
    "Ative compromissos, eventos ou comunidades para gerenciá-los aqui.",
  specifyQuickSessionPriceDuration:
    "Especifique o preço e a duração do compromisso rápido",
  pleaseEnterEmailAccessQuickSession:
    "Por favor, insira seu e-mail para acessar o compromisso rápido",
  sessionRescheduledByHost: "Seu compromisso foi reagendado pelo anfitrião",
  active: "Ativo",
  headline: "Título",
  userName: "Nome de usuário",
  turnOf: "Desligar",
  promotion: "Promoção",
  qRCode: "Código QR",
  timeframe: "Prazo",
  youAreInPreviewMode:
    "Você está no modo de visualização. Para interagir com o perfil, clique abaixo para sair deste modo!",
  backToMyProfile: "Voltar para o meu perfil",
  previewMode: "Modo de visualização",
  editMenu: "Editar menu",
  createMenu: "Criar menu",
  digitalProduct: "Produto digital",
  buyTicketsHere: "Compre ingressos aqui",
  unsubscribedSuccessfully: "Cancelamento de inscrição bem-sucedido",

  errorWhileSubscribing: "Erro ao se inscrever",
  errorWhileUnsubscribing: "Erro ao cancelar a inscrição",
  add: "Adicionar",
  bio: "Biografia",
  bioTooLong: "Por favor, limite sua biografia a 300 caracteres",
  publicIdNotAllowed:
    "Este ID público não pode ser criado. Por favor, escolha outro.",
  telegram: "Telegram",
  whatsApp: "WhatsApp",
  email: "E-mail",
  instagram: "Instagram",
  less: "Menos",
  moreText: "Mais",
  buyAccessForFree: "Obter acesso grátis",
};
